import i18n from '~/i18n';
import taskUtils from '~/utils/task-utils';
import { store } from '~/store';
import { DateTime } from 'luxon';
import { setSelectedTaskRowId } from '~/reducers/selectedTaskRowIdSlice';
import { resetSelectedMapStops } from '~/reducers/selectedMapStopsSlice';
import { makeMarkerPopup } from './makeMarkerPopup';
import { getMetricsFromStopOrTask } from '~/utils/stopUtils';
import constants from '~/utils/constants';
import timeUtils from '~/utils/time-utils';

const { TIME_FORMAT_12H } = constants;

const getDelayConfig = (stop) => {
    const { isEarly, isOnTime, isAtRisk, isLate } = stop;
    if (isEarly)
        return {
            icon: 'clockFill',
            color: 'earth',
            text: 'markerPopup:status.early'
        };
    if (isOnTime)
        return {
            icon: 'clockFill',
            color: 'earth',
            text: 'markerPopup:status.onTime'
        };
    if (isAtRisk)
        return {
            icon: 'clockFill',
            color: 'venus',
            text: 'markerPopup:status.atRisk'
        };
    if (isLate)
        return {
            icon: 'clockFill',
            color: 'mars',
            text: 'markerPopup:status.late'
        };

    return { icon: null, color: null, text: null };
};

/**
 * Creates a `MarkerPopup` component for plan stops and unassigned tasks
 * @method makeStopPopup
 * @memberof module:utils/map/popupMaker
 * @param {*} payload - stop data (PlanStop or OnDemandDispatchStop)
 * @param {*} emittedEventHandler - the map emitted event handler
 * @param timeZone - The specified time zone
 * @param isClientTimezoneFlagEnabled - Flag indicating whether to format the time using the client's time zone
 * @returns {MarkerPopup}
 */
export const makeStopPopup = ({
    payload,
    emittedEventHandler,
    timeZone,
    isClientTimezoneFlagEnabled
}) => {
    const { location, stopMarkerData: planStop } = payload;

    const {
        rawEta,
        euid,
        // TODO: API needs to send back `serviceTimeML` for tasks
        isServiceTimeML,
        stopName,
        labels,
        taskId,
        volumeCapacityUsed,
        sizeByCompartment,
        clientId,
        size,
        twoPartTaskId,
        recommendationScore,
        numInventoryItems = 0,
        isPickup
    } = planStop;

    const {
        icon: stopIcon,
        color: stopColor,
        text: stopText
    } = getDelayConfig(planStop);

    const etaTime = rawEta
        ? i18n.t('common:time.estimatedTime', {
              time: timeUtils.formatTimeInTimeZone({
                  time: DateTime.fromMillis(rawEta).toISO(),
                  timeZone,
                  format: TIME_FORMAT_12H,
                  isClientTimezoneFlagEnabled
              })
          })
        : i18n.t('common:N/A');
    const { serviceTime, timeWindows } = getMetricsFromStopOrTask({
        stop: planStop,
        timeZone,
        isClientTimezoneFlagEnabled,
        format: TIME_FORMAT_12H
    });

    // TODO: RP-719 Refactor to return React components
    const { isMultiCompartmentClient = false } =
        store.getState().accountSetupByClient[clientId] || {};

    const onClickStopDetailButton = () => {
        store.dispatch(resetSelectedMapStops());
        store.dispatch(setSelectedTaskRowId(twoPartTaskId || taskId));
    };

    const popupPayload = {
        className: 'stop-popup',
        testId: 'stop-popup',
        location,
        stopData: {
            stopColor,
            stopText: i18n.t(stopText),
            stopName,
            stopIcon,
            etaTime,
            labels,
            isMultiCompartmentClient,
            sizeByCompartment,
            timeWindows,
            serviceTime: `${serviceTime} ${i18n.t('common:time.minutes')}`,
            isServiceTimeML,
            MLServiceTimeChipText: i18n.t('markerPopup:machineLearned'),
            routeId: taskUtils.getEuid(euid),
            volumeCapacityUsed: volumeCapacityUsed ?? size,
            stopDetailButtonText: i18n.t('markerPopup:button.moreDetail'),
            onClickStopDetailButton,
            numInventoryItems,
            taskId,
            recommendationScore,
            isPickup,
            twoPartTaskId
        }
    };

    return makeMarkerPopup(popupPayload, emittedEventHandler);
};
