import { MutableRefObject } from 'react';
import { isNumber } from 'lodash';
import colors from '~/ui/theme/colors';
import {
    Coordinates,
    GoogleMapsApi,
    GoogleMapsCircle,
    GoogleMapsInstance,
    GoogleMapsPolygon,
    GoogleMapsPolyline,
    GoogleMapsPolylineOptions
} from '~/api/types';
import { RouteLinePath } from '~/ui/components/RouteLine/types';
import { googlePolygonToGeoJSONPolygon } from '~/utils/map-utils';

/**
 * Select google maps objects supported by the map drawing utils module
 *
 * Currently supports: `Circle` | `Polygon` | `Polyline`
 */
export type SupportedGoogleMapObjects =
    | GoogleMapsCircle
    | GoogleMapsPolygon
    | GoogleMapsPolyline;

/** Draws a suported google map object on a specific map instance
 *
 * @param mapObject - an instance of the google map oobject to draw
 * @param mapInstance - the google map instance
 */
const drawMapObject = (
    mapObject: SupportedGoogleMapObjects,
    mapInstance: GoogleMapsInstance
) => {
    mapObject.setMap(mapInstance);
};

/**
 * Removes a supported google map object from the map
 *
 * @param mapObject - an instance of the google map object to draw
 */
const removeMapObject = (mapObject: SupportedGoogleMapObjects) => {
    mapObject.setMap(null);
};

/**
 * Preset options for the `makePolygon` utility
 */
const DEFAULT_MAKE_CIRCLE_OPTIONS = {
    strokeColor: colors.palette.ocean,
    strokeOpacity: 1,
    strokeWeight: 3,
    fillColor: colors.palette.ocean,
    fillOpacity: 0.3
};

/**
 * Draws a predefined google maps `Circle` at a specified center and radius
 *
 * @param mapsApi - the google maps API instance
 * @param lat - the circle center's latitude
 * @param lng - the circle center's longtitude
 * @param radius - the circle's radius in meters
 */
const makeCircle = (
    mapsApi: GoogleMapsApi,
    lat: Coordinates['lat'],
    lng: Coordinates['lng'],
    radius: number
) => {
    const circleOptions = {
        ...DEFAULT_MAKE_CIRCLE_OPTIONS,
        center: {
            lat,
            lng
        },
        radius
    };

    return new mapsApi.Circle(circleOptions);
};

/**
 * Customization options for the drawn polygons
 */
export interface MakePolygonDrawingOptions {
    /**
     * The stroke and fill color of the polygon
     */
    color?: string;

    /**
     * The stroke weight of the polygon
     */
    strokeWeight?: number;
    /**
     * Whether polygon is editable
     */
    editable?: boolean;
    /**
     * Opacity of fill color
     */
    fillOpacity?: number;
    /**
     * Whether polygon is clickable
     */
    clickable?: boolean;
    /**
     * Rendering z-index
     */
    zIndex?: number;
    /**
     * Whether polygon is draggable
     */
    draggable?: boolean;
}

const DEFAULT_POLYGON_HIGHLIGHT_FILL_OPACITY = 0.6;

const DEFAULT_POLYGON_FILL_OPACITY = 0.3;

/**
 * Preset options for the `makePolygon` utility
 */
const DEFAULT_MAKE_POLYGON_OPTIONS = {
    strokeColor: colors.palette.ocean,
    strokeOpacity: 1,
    strokeWeight: 1,
    fillColor: colors.palette.ocean,
    fillOpacity: DEFAULT_POLYGON_FILL_OPACITY
};

/**
 * Draws an editable google maps `Polygon` at a specified coordinates
 *
 * @param polygonPaths - an array of coordinates defining the polygon
 * @param makePolygonOptions - customization options for the drawn polygons
 */

/**
 * Draws a predefined google maps `Polygon` at a specified coordinates
 *
 * @param polygonPaths - an array of coordinates defining the polygon
 * @param makePolygonOptions - customization options for the drawn polygons
 */
const makePolygon = (
    polygonPaths: Coordinates[],
    makePolygonOptions: MakePolygonDrawingOptions = {
        color: colors.palette.ocean,
        strokeWeight: 1
    }
) => {
    const { color, strokeWeight } = makePolygonOptions;
    const { strokeColor: defaultStrokeColor, fillColor: defaultFillColor } =
        DEFAULT_MAKE_POLYGON_OPTIONS;

    const strokeColor = color || defaultStrokeColor;
    const fillColor = color || defaultFillColor;

    const polygonOptions = {
        ...DEFAULT_MAKE_POLYGON_OPTIONS,
        ...makePolygonOptions,
        strokeColor,
        strokeWeight,
        fillColor
    };
    const polygon = new google.maps.Polygon();
    polygon.setPaths(polygonPaths);
    polygon.setOptions(polygonOptions);
    return polygon;
};

const makeResizablePolygon = (
    polygonPaths: Coordinates[],
    makePolygonOptions: MakePolygonDrawingOptions
) => {
    const options = {
        ...makePolygonOptions,
        clickable: true,
        editable: true,
        zIndex: 1
    };
    return makePolygon(polygonPaths, options);
};

export const displayResizablePolygon = (
    polygon: GoogleMapsPolygon,
    mapInstance: GoogleMapsInstance
) => {
    const cleanupResizablePolygon = () => {
        polygon.setMap(null);
    };
    drawMapObject(polygon, mapInstance);
    return cleanupResizablePolygon;
};

/**

/**
 * Retrieves a specific map object from a given mapping object
 *
 * @param allMapObjects - a map of supported map objects, keyed by `id`
 * @param id - the id of the map object to retrieve
 */
const getMapObjectFromAll = (
    allMapObjects: Record<string, SupportedGoogleMapObjects>,
    id: string
) => {
    return allMapObjects[id];
};

/**
 * Draws all map objects from given mapping object on a specific map instance
 *
 * @param mapInstance - the google map instance
 * @param allMapObjects - a map of supported map objects, keyed by `id`
 */
const drawAllMapObjects = (
    mapInstance: GoogleMapsInstance,
    allMapObjects: Record<string, SupportedGoogleMapObjects>
) => {
    for (const id in allMapObjects) {
        const mapObject = getMapObjectFromAll(allMapObjects, id);
        drawMapObject(mapObject, mapInstance);
    }
};

/**
 * Removes all map objects from given mapping object on a specific map instance
 *
 * @param allMapObjects - a map of supported map objects, keyed by `id`
 */
const removeAllMapObjects = (
    allMapObjects: Record<string, SupportedGoogleMapObjects>
) => {
    for (const id in allMapObjects) {
        const mapObject = getMapObjectFromAll(allMapObjects, id);
        removeMapObject(mapObject);
    }
};

/**
 * Highlights a specific google maps `Polygon` instance
 *
 * @param polygon - the google maps `Polygon` instance to highlight
 * @param fillOpacity - the fill opacity to update the polygon to
 */
const highlightPolygon = (
    polygon: GoogleMapsPolygon,
    fillOpacity = DEFAULT_POLYGON_HIGHLIGHT_FILL_OPACITY
) => {
    polygon.setOptions({
        fillOpacity
    });
};

/**
 * Unhighlights a specific google maps `Polygon` instance
 *
 * @param polygon - the google maps `Polygon` instance to unhighlight
 * @param fillOpacity - the fill opacity to update the polygon to
 */
const unHighlightPolygon = (
    polygon: GoogleMapsPolygon,
    fillOpacity = DEFAULT_POLYGON_FILL_OPACITY
) => {
    polygon.setOptions({
        fillOpacity
    });
};

/**
 * Emphasizes a polygon
 *
 * @param isShowingRoutePolygons - whether the `show route polygons` setting is enabled
 * @param polygon - the google maps `Polygon` instance
 * @param mapInstance - the google map instance
 */
const emphasizePolygon = (polygon: GoogleMapsPolygon) => {
    highlightPolygon(polygon);
};

/**
 * Get coordinates of a polygon
 *
 * @param polygon - the google maps `Polygon` instance
 */
const getPolygonCoordinates = (polygon: GoogleMapsPolygon) => {
    return googlePolygonToGeoJSONPolygon(polygon);
};
/**
 * Deemphasizes a polygon
 *
 * @param isShowingRoutePolygons - whether the `show route polygons` setting is enabled
 * @param polygon - the google maps `Polygon` instance
 */
const unEmphasizePolygon = (polygon: GoogleMapsPolygon) => {
    unHighlightPolygon(polygon);
};

/**
 * Draws a predefined circle on a specific map instance
 *
 * @param mapInstance - the google map instance
 * @param mapsApi - the google maps API instance
 * @param center - the circle's center
 * @param radius - the circle's radius in meters
 */
const drawCircleOnMap = (
    mapInstance: GoogleMapsInstance,
    mapsApi: GoogleMapsApi,
    center: Coordinates,
    radius: number
) => {
    const { lat, lng } = center;
    const circle = makeCircle(mapsApi, lat, lng, radius);
    drawMapObject(circle, mapInstance);
    return circle;
};

/**
 * Decodes an encoded polyline path
 * @param encodedPath - the encoded polyline path string
 *
 * @see {@link https://developers.google.com/maps/documentation/javascript/reference/geometry}
 */
const decodePolylinePath = (encodedPath: string): google.maps.LatLng[] => {
    return google.maps.geometry.encoding.decodePath(encodedPath);
};

const createPolyline = (
    path: RouteLinePath,
    options: GoogleMapsPolylineOptions
) => {
    const polyline = new google.maps.Polyline();
    polyline.setPath(path);
    polyline.setOptions(options);
    return polyline;
};

const updatePolyline = (
    path: RouteLinePath,
    lineRef: MutableRefObject<GoogleMapsPolyline | null>,
    style?: GoogleMapsPolylineOptions
) => {
    if (lineRef.current) {
        lineRef.current.setPath(path);
        lineRef.current.setOptions(style as GoogleMapsPolylineOptions);
    } else {
        const lineOptions = {
            ...style,
            path
        };
        const polyline = new google.maps.Polyline();
        polyline.setOptions(lineOptions);
        lineRef.current = polyline;
    }
    return lineRef;
};

const getPolylineStyle = ({
    strokeColor,
    strokeWeight,
    strokeOpacity,
    icons
}: GoogleMapsPolylineOptions) => {
    return {
        ...(strokeColor && { strokeColor }),
        ...(isNumber(strokeWeight) && { strokeWeight }),
        ...(icons && { icons }),
        ...(isNumber(strokeOpacity) && { strokeOpacity })
    };
};

export const mapDrawingUtils = {
    makeCircle,
    makePolygon,
    makeResizablePolygon,
    displayResizablePolygon,
    drawMapObject,
    removeMapObject,
    getMapObjectFromAll,
    drawAllMapObjects,
    removeAllMapObjects,
    highlightPolygon,
    unHighlightPolygon,
    emphasizePolygon,
    unEmphasizePolygon,
    getPolygonCoordinates,
    drawCircleOnMap,
    decodePolylinePath,
    updatePolyline,
    createPolyline,
    getPolylineStyle
};
