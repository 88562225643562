import { DateTime } from 'luxon';
import timeUtils from '~/utils/time-utils';

import { useFeatureFlag, useClientTimezone } from '~/hooks';
import { FeatureFlag } from '~/utils/feature-flags-utils';
import { useCallback } from 'react';

export const useFormatTimeToClientTimeZone = () => {
    const { timezone: selectedClientTimeZone } = useClientTimezone();

    const isClientTimezoneFlagEnabled = useFeatureFlag(
        FeatureFlag.CLIENT_TIMEZONE
    );

    const formatTimeToClientTimeZone = useCallback(
        ({
            time,
            format = 't',
            shouldFormatTime = true
        }: {
            time: string | DateTime;
            format?: string;
            shouldFormatTime?: boolean;
        }) => {
            return timeUtils.formatTimeInTimeZone({
                time,
                timeZone: selectedClientTimeZone,
                format,
                shouldFormatTime,
                isClientTimezoneFlagEnabled
            });
        },
        [selectedClientTimeZone, isClientTimezoneFlagEnabled]
    );

    return { formatTimeToClientTimeZone, selectedClientTimeZone };
};
