import React, { useCallback, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useRunRoutePlanner } from '~/components/MapPage/PlanStopsControl/useRunRoutePlanner';
import { hasAssignedStops } from '~/components/MapPage/PlanStopsControl/utils';
import ResequenceModalStopList from '~/components/MapPage/ResequenceModal/ResequenceModalStopList';
import { TextWithLineBreaks } from '~/components/TextWithLineBreaks';
import { useUnassignStops } from '~/hooks';
import { selectIsOpenUnassignedTasksDrawer } from '~/reducers/mapDrawerSettingsSlice';
import {
    replaceSelectedMapStop,
    selectSelectedMapStops
} from '~/reducers/selectedMapStopsSlice';
import {
    ManageStopControlMenu,
    ManageStopControlMultiple,
    StopModalMenuButton
} from '~/ui';
import constants from '~/utils/constants';
import { mapPlanStopUtils } from '~/utils/map';
import { CreateTripButton } from '../CreateTripButton';

import './PlanMultiStopsControl.scss';

function PlanMultiStopsControl({
    selectedStops,
    stopIdPropertyName,
    footerButtonTitle,
    footerButtonIcon,
    onClickFooterButton,
    onSwitchView,
    disableResequencing = false
}) {
    const { t } = useTranslation('translation');
    const dispatch = useDispatch();
    const isOpenUnassignedTasksDrawer = useSelector(
        selectIsOpenUnassignedTasksDrawer
    );
    const selectedMapStopIds = useSelector(selectSelectedMapStops);
    const [dragStartIndex, setDragStartIndex] = useState(null);

    const { unassignStops, isUnassignStopsAllowed } = useUnassignStops();

    const { handleClickRunRoutePlanner, ReRunSchedulerModal } =
        useRunRoutePlanner();

    const showRunRoutePlanner = !hasAssignedStops(selectedMapStopIds);

    const handleDrop = useCallback(
        (e, index) => {
            const reorderedStops = mapPlanStopUtils.processStopsReorder(
                e,
                selectedStops,
                stopIdPropertyName,
                dragStartIndex,
                index,
                isOpenUnassignedTasksDrawer
            );
            dispatch(replaceSelectedMapStop(reorderedStops));
        },
        [
            dispatch,
            dragStartIndex,
            isOpenUnassignedTasksDrawer,
            selectedStops,
            stopIdPropertyName
        ]
    );

    const stopList = useMemo(() => {
        return selectedStops.map((selectedStop, index) => (
            <ResequenceModalStopList
                key={selectedStop[stopIdPropertyName]}
                stopIdPropertyName={stopIdPropertyName}
                selectedStop={selectedStop}
                handleDragStart={() => setDragStartIndex(index)}
                handleDragOver={(e) => e.preventDefault()}
                handleDrop={(e) => handleDrop(e, index)}
            />
        ));
    }, [selectedStops, handleDrop, stopIdPropertyName]);

    return (
        <ManageStopControlMultiple
            className="plan-multi-stops-control"
            controlTitle={t('PlanStopsControl.title.no_of_stops_selected', {
                length: selectedMapStopIds.length
            })}
            controlHelp={t('PlanStopsControl.title.selection_info')}
            stopList={stopList}
            footerButtonTitle={footerButtonTitle}
            footerButtonIcon={footerButtonIcon}
            onClickFooterButton={onClickFooterButton}
        >
            <ManageStopControlMenu>
                <StopModalMenuButton
                    disabled={disableResequencing}
                    buttonIcon="iconReorder"
                    buttonText={t('PlanStopsControl.button.resequence')}
                    onClick={() =>
                        onSwitchView(constants.stopsControl.views.RESEQUENCE)
                    }
                    showButtonArrow
                    data-testid="button-resequence"
                />
            </ManageStopControlMenu>
            <div className="_d-flex _ai-center plan-multi-stops-control__selected-stops-info">
                <span className="_text-3-alt">
                    {t('PlanStopsControl.title.selected_stops_info')}
                </span>
            </div>
            <ManageStopControlMenu>
                <StopModalMenuButton
                    buttonIcon="iconRouteFill"
                    buttonText={t('PlanStopsControl.button.reassign')}
                    showButtonArrow
                    onClick={() =>
                        onSwitchView(constants.stopsControl.views.REASSIGN)
                    }
                    data-testid="button-reassign"
                />
                <StopModalMenuButton
                    disabled={!isUnassignStopsAllowed}
                    onClick={unassignStops}
                    buttonIcon="iconRouteless"
                    buttonText={t('PlanStopsControl.button.unassign')}
                    data-testid="button-plan-stop-unassign_button"
                />
                <CreateTripButton />
            </ManageStopControlMenu>

            {showRunRoutePlanner && (
                <ManageStopControlMenu className="plan-multi-stops-control__control-menu">
                    <StopModalMenuButton
                        onClick={handleClickRunRoutePlanner}
                        className="plan-multi-stops-control__run-route-planner-button"
                        buttonIcon="autoplay"
                        buttonIconColor="white"
                        buttonText={
                            <TextWithLineBreaks
                                text={t(
                                    'PlanStopsControl.button.runRoutePlanner'
                                )}
                            />
                        }
                        data-testid="run-route-planner-menu-button"
                    />
                    {ReRunSchedulerModal}
                </ManageStopControlMenu>
            )}
        </ManageStopControlMultiple>
    );
}

export default PlanMultiStopsControl;
