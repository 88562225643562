import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
    ManageStopControlMenu,
    ManageStopControlSingle,
    StopModalMenuButton
} from '~/ui';

import { useRunRoutePlanner } from '~/components/MapPage/PlanStopsControl/useRunRoutePlanner';
import { hasAssignedStops } from '~/components/MapPage/PlanStopsControl/utils';
import { TextWithLineBreaks } from '~/components/TextWithLineBreaks';
import { useUnassignStops } from '~/hooks';
import { selectSelectedMapStops } from '~/reducers/selectedMapStopsSlice';
import constants from '~/utils/constants';
import { DuplicateTaskButton } from '../DuplicateTaskButton';
import { CreateTripButton } from '../CreateTripButton';

import './PlanSingleStopControl.scss';

function PlanSingleStopControl({
    stopData,
    footerButtonTitle,
    footerButtonIcon,
    onClickFooterButton,
    onSwitchView,
    disableResequencing = false
}) {
    const { t } = useTranslation('translation');
    const { unassignStops, isUnassignStopsAllowed } = useUnassignStops();
    const { handleClickRunRoutePlanner, ReRunSchedulerModal } =
        useRunRoutePlanner();
    const selectedMapStopIds = useSelector(selectSelectedMapStops);
    const isPlannedStopsSelected = hasAssignedStops(selectedMapStopIds);

    const showRunRoutePlannerButton = !isPlannedStopsSelected;

    return (
        <ManageStopControlSingle
            className="plan-single-stop-control"
            stopData={stopData}
            footerButtonTitle={footerButtonTitle}
            footerButtonIcon={footerButtonIcon}
            onClickFooterButton={onClickFooterButton}
        >
            <ManageStopControlMenu>
                <StopModalMenuButton
                    buttonIcon="iconReorder"
                    buttonText={t('PlanStopsControl.button.resequence')}
                    onClick={() =>
                        onSwitchView(constants.stopsControl.views.RESEQUENCE)
                    }
                    buttonStopText={t('PlanStopsControl.button.stopText', {
                        stopIndex: stopData.stopNumber
                    })}
                    disabled={disableResequencing}
                    showButtonArrow
                    data-testid="button-resequence"
                />
                <StopModalMenuButton
                    buttonIcon="iconRouteFill"
                    buttonText={t('PlanStopsControl.button.reassign')}
                    showButtonArrow
                    onClick={() =>
                        onSwitchView(constants.stopsControl.views.REASSIGN)
                    }
                    data-testid="button-reassign"
                />
                <StopModalMenuButton
                    disabled={!isUnassignStopsAllowed}
                    onClick={unassignStops}
                    buttonIcon="iconRouteless"
                    buttonText={t('PlanStopsControl.button.unassign')}
                    data-testid="button-plan-stop-unassign"
                />
                <DuplicateTaskButton taskId={stopData.taskId} />
                <CreateTripButton />
            </ManageStopControlMenu>
            {showRunRoutePlannerButton && (
                <ManageStopControlMenu className="plan-single-stop-control__control-menu">
                    <StopModalMenuButton
                        onClick={handleClickRunRoutePlanner}
                        className="plan-single-stop-control__menu-button"
                        buttonIcon="autoplay"
                        buttonIconColor="white"
                        buttonText={
                            <TextWithLineBreaks
                                text={t(
                                    'PlanStopsControl.button.runRoutePlanner'
                                )}
                            />
                        }
                        data-testid="run-route-planner-menu-button"
                    />
                    {ReRunSchedulerModal}
                </ManageStopControlMenu>
            )}
        </ManageStopControlSingle>
    );
}

export default PlanSingleStopControl;
