import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectClients } from '~/reducers/clientsSlice';
import { selectMainClient } from '~/reducers/mainClientSlice';
import { selectSelectedClients } from '~/reducers/selectedClientsSlice';

export const useClientTimezone = () => {
    const clients = useSelector(selectClients);
    const mainClient = useSelector(selectMainClient);
    const selectedClients = useSelector(selectSelectedClients);

    return useMemo(() => {
        const clientIds = Object.keys(selectedClients || {});
        const isSingleClientSelected = clientIds.length === 1;
        const selectedClient = isSingleClientSelected
            ? clients[clientIds[0]]
            : mainClient;

        return {
            client: selectedClient,
            timezone: selectedClient?.timezone || 'system'
        };
    }, [selectedClients, mainClient, clients]);
};
