import axios from 'axios';
import constants from '~/utils/constants';

/**
 * Sets the global axios base url and headers
 * @param {object} mcwAppInit
 */
export function initAxios(mcwAppInit) {
    if (!mcwAppInit) {
        throw new Error('missing app init');
    }
    axios.defaults.baseURL = mcwAppInit.data.apiUrl;
    axios.defaults.headers.common = Object.assign(
        axios.defaults.headers.common,
        {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${mcwAppInit.requestHeaders.authorization}`,
            'X-OAuth-IDToken': mcwAppInit.requestHeaders['x-oauth-idtoken'],
            [constants.requestHeaders.WISE_CLIENT_ID]:
                mcwAppInit.requestHeaders[
                    constants.requestHeaders.WISE_CLIENT_ID
                ]
        }
    );
}

const updateAxiosWiseClientHeader = (mainClientId) => {
    axios.defaults.headers[constants.requestHeaders.WISE_CLIENT_ID] =
        mainClientId;
};

const updateAxiosWiseClientIdsHeader = (selectedClientIds) => {
    axios.defaults.headers[constants.requestHeaders.WISE_CLIENT_IDS] =
        JSON.stringify(selectedClientIds);
};

const getAppConfig = () => {
    const mcwAppInit =
        localStorage.getItem(constants.localStorageKeys.MCW_APP_INIT) || '';
    if (!mcwAppInit) {
        return;
    }
    return JSON.parse(mcwAppInit);
};

const updateWiseClientIdInAppConfig = (mainClientId) => {
    const mcwAppConfig = getAppConfig();
    if (!mcwAppConfig) {
        return;
    }
    mcwAppConfig.requestHeaders[constants.requestHeaders.WISE_CLIENT_ID] =
        mainClientId;
    localStorage.setItem(
        constants.localStorageKeys.MCW_APP_INIT,
        JSON.stringify(mcwAppConfig)
    );
};

const updateWiseClientIdsInAppConfig = (selectedClientIds) => {
    const mcwAppConfig = getAppConfig();
    if (!mcwAppConfig) {
        return;
    }
    mcwAppConfig.requestHeaders[constants.requestHeaders.WISE_CLIENT_IDS] =
        selectedClientIds;
    localStorage.setItem(
        constants.localStorageKeys.MCW_APP_INIT,
        JSON.stringify(mcwAppConfig)
    );
};

// TO BE DEPRECATED AND REPLACED WITH `WISE_CLIENT_IDS` header
export function updateWiseClientIdHeader(mainClientId) {
    updateAxiosWiseClientHeader(mainClientId);
    updateWiseClientIdInAppConfig(mainClientId);
}

export function updateWiseClientIdsHeader(selectedClientIds) {
    updateAxiosWiseClientIdsHeader(selectedClientIds);
    updateWiseClientIdsInAppConfig(selectedClientIds);
}

export function getUserDetails() {
    const mcwAppConfig = getAppConfig();
    return mcwAppConfig?.data?.userDetails;
}

export function getUserAccessToClients() {
    return getUserDetails()?.access;
}
