import { AddressInputFields } from '~/components/AddTaskModal/utils/addTaskModalUtils';
import {
    GoogleMapsPlaceResult,
    GoogleMapsGeocoderAddressComponent,
    GoogleMapsLatLng,
    GoogleMapsPolygon
} from '~/api/types';

import { ZoneCoordinates } from '~/components/ZoneManagementPage/types';

export const googlePolygonToGeoJSONPolygon = (
    googlePolygon: GoogleMapsPolygon | null
): ZoneCoordinates | null => {
    if (!googlePolygon) {
        return null;
    }
    const polygon = googlePolygon.getPath().getArray();
    const geoJsonPolygon: number[][] = [];
    polygon.forEach((point: GoogleMapsLatLng) => {
        geoJsonPolygon.push([point.lng(), point.lat()]);
    });
    // GeoJson must have matching first and last points, while Google Polygon does not
    geoJsonPolygon.push(geoJsonPolygon[0]);
    return [geoJsonPolygon];
};

/* eslint-disable camelcase */
export interface AddressComponent {
    street_number?: string;
    route?: string;
    administrative_area_level_1?: string;
    sublocality_level_1?: string;
    sublocality_level_2?: string;
    sublocality_level_3?: string;
    sublocality_level_4?: string;
    premise?: string;
    country?: string;
    locality?: string;
    neighborhood?: string;
    postal_code?: string;
    postal_town?: string;
}

function googlePlaceToAddress(
    place: GoogleMapsPlaceResult
): AddressInputFields {
    const result: AddressInputFields = {};

    if (!place.address_components) {
        return result;
    }
    const components: AddressComponent = {};
    place.address_components.forEach(
        (component: GoogleMapsGeocoderAddressComponent) => {
            const addressType = component.types[0] as keyof AddressComponent;
            components[addressType] =
                component.short_name || component.long_name;
        }
    );

    if (place.name) {
        result.customerName = place.name;
    }
    const addressLineKeys = [
        'street_number',
        'route',
        'sublocality_level_1',
        'sublocality_level_2',
        'sublocality_level_3',
        'sublocality_level_4',
        'premise'
    ];
    result.addressCity =
        components.locality ||
        components.sublocality_level_1 ||
        components.postal_town;
    const addressLine1 = addressLineKeys.reduce((preVal, currVal) => {
        const separator = currVal === 'premise' ? '-' : ' ';
        if (
            result.addressCity === components[currVal as keyof AddressComponent]
        ) {
            return preVal;
        }
        return components[currVal as keyof AddressComponent]
            ? preVal + separator + components[currVal as keyof AddressComponent]
            : preVal;
    }, '');
    result.addressStreet = addressLine1.trim();
    result.addressZipCode = components.postal_code;
    result.addressState = components.administrative_area_level_1;
    const obtainLat = place.geometry?.location?.lat().toString() || '';
    const obtainLng = place.geometry?.location?.lng().toString() || '';
    if (obtainLat !== '' && obtainLng !== '') {
        result.latitude = obtainLat;
        result.longitude = obtainLng;
    }
    return result;
}

export const mapUtils = { googlePolygonToGeoJSONPolygon, googlePlaceToAddress };
