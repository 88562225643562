import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LiveDriver from '~/data-classes/dispatched/LiveDriver';
import {
    setHasIsolatedRoutes,
    setMapMarkerMode
} from '~/reducers/mapSettingsSlice';
import { resetPlanStopsLevelData } from '~/reducers/planStopsLevelDataSlice';
import {
    resetSelectedDrawerCardData,
    upsertSelectedDrawerCardData
} from '~/reducers/selectedDrawerCardDataSlice';
import {
    resetSelectedDrawerCardId,
    selectSelectedDrawerCardId,
    setSelectedDrawerCardId
} from '~/reducers/selectedDrawerCardIdSlice';
import {
    addSelectedMapRoute,
    removeSelectedMapRoute,
    resetSelectedMapRoutes,
    selectSelectedMapRoutes,
    setSelectedMapRoute
} from '~/reducers/selectedMapRoutesSlice';
import { resetSelectedMapStops } from '~/reducers/selectedMapStopsSlice';
import { resetSelectedTaskRowId } from '~/reducers/selectedTaskRowIdSlice';
import { RootState } from '~/store';
import constants from '~/utils/constants';
import { RoutesWithTasks } from '../useRoutes/useRoutesWithTasks';

/**
 * Live Routes Hook
 *
 * @category Hooks
 * @module hooks/useLiveRoutesUtils
 *
 * @example
 * import { useLiveRoutesUtils } from '~/hooks/useLiveRoutesUtils';
 */
export const useLiveRoutesUtils = () => {
    const dispatch = useDispatch();
    const mapSettings = useSelector((state: RootState) => state.mapSettings);
    const selectedDrawerCardId = useSelector(selectSelectedDrawerCardId);
    const selectedMapRoutes = useSelector(selectSelectedMapRoutes);
    const { viewCardDetails, isMultipleCardSelectEnabled } = mapSettings;

    const isDriverSelected = useCallback(
        (clientDriverId: string) => {
            return (
                selectedDrawerCardId === clientDriverId ||
                selectedMapRoutes.includes(clientDriverId)
            );
        },
        [selectedDrawerCardId, selectedMapRoutes]
    );

    /**
     * Sets several redux stores related to the target driver
     *
     * @param {Object} driverData the driver data
     */
    const selectDriver = useCallback(
        (driverData: RoutesWithTasks) => {
            const { clientDriverId } = driverData;
            // make object serializable for redux
            const serialLiveDriverData =
                driverData instanceof LiveDriver
                    ? driverData.toJSON()
                    : driverData;
            dispatch(
                upsertSelectedDrawerCardData({
                    id: clientDriverId,
                    data: serialLiveDriverData
                })
            );
            dispatch(setMapMarkerMode(constants.mapMarkerModes.STOPS_CLUSTERS));
            dispatch(setSelectedDrawerCardId(clientDriverId));

            if (!viewCardDetails) {
                dispatch(setSelectedMapRoute(clientDriverId));
                dispatch(setHasIsolatedRoutes(true));
            }
        },
        [dispatch, viewCardDetails]
    );

    /**
     * Resets several redux stores related to the target driver
     */
    const deselectDriver = useCallback(() => {
        dispatch(resetPlanStopsLevelData());
        dispatch(resetSelectedDrawerCardData());
        dispatch(resetSelectedDrawerCardId());
        dispatch(resetSelectedMapStops());
        dispatch(resetSelectedTaskRowId());
        dispatch(setMapMarkerMode(constants.mapMarkerModes.ROUTES));
        dispatch(setHasIsolatedRoutes(false));
        if (!isMultipleCardSelectEnabled) {
            dispatch(resetSelectedMapRoutes({}));
        }
    }, [dispatch, isMultipleCardSelectEnabled]);

    const isSelectingMultipleCards =
        isMultipleCardSelectEnabled && !viewCardDetails;

    /**
     * Handles selecting a driver using a marker or a card
     *
     * By default, the function will display the selected driver into a map drawer
     *
     * When multi-select is enabled, the function adds/removes the selected driver from the list
     *
     * @param {Boolean} isSelected indicates whether the driver is selected
     * @param {Object} driverData the driver data
     */
    const handleDriverSelect = useCallback(
        (isSelected: boolean, driverData: RoutesWithTasks) => {
            const { clientDriverId } = driverData;

            // selecting multiple
            if (isSelectingMultipleCards) {
                dispatch(
                    isSelected
                        ? addSelectedMapRoute(clientDriverId)
                        : removeSelectedMapRoute(clientDriverId)
                );
                return;
            }

            // selecting singles
            if (isSelected) {
                selectDriver(driverData);
            } else {
                deselectDriver();
            }
        },
        [dispatch, deselectDriver, isSelectingMultipleCards, selectDriver]
    );

    return {
        isDriverSelected,
        selectDriver,
        deselectDriver,
        isSelectingMultipleCards,
        handleDriverSelect
    };
};
