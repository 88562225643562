import { createSlice } from '@reduxjs/toolkit';
import { resetOnLogout } from '~/reducers/common-actions';
import type { RootState } from '~/store';

/**
 * State related to zones
 */
type ZoneManagementState = {
    isCreatingZoneFromRoute: boolean;
};

export const zoneManagementSlice = createSlice({
    name: 'zoneManagement',
    initialState: { isCreatingZoneFromRoute: false } as ZoneManagementState,
    reducers: {
        setIsCreatingZoneFromRoute: (state, action) => {
            state.isCreatingZoneFromRoute = action.payload;
            return state;
        },
        resetZoneManagement: (): ZoneManagementState => {
            return { isCreatingZoneFromRoute: false };
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetOnLogout, () => {
            return { isCreatingZoneFromRoute: false };
        });
    }
});

export const { setIsCreatingZoneFromRoute, resetZoneManagement } =
    zoneManagementSlice.actions;

export const selectIsCreatingZoneFromRoute = (state: RootState): boolean =>
    state.zoneManagement.isCreatingZoneFromRoute;

export default zoneManagementSlice.reducer;
