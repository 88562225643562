import React from 'react';
import { isArray } from 'lodash';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Icon, TooltipButton } from '~/ui';
import { useZones, useZoneVehicleAssociations } from '~/hooks';
import { IconSizes } from '~/ui/components/Icon/IconSizes';

import { NoZone } from './NoZone';
import { ListItem } from './ListItem';

export const ZoneList = () => {
    const { data, downloadZones } = useZones();
    const { zoneVehicleAssociations = {} } = useZoneVehicleAssociations();

    const { t } = useTranslation('zoneManagement');
    const zones = data || [];
    const isZonesAvailable = isArray(zones) && Boolean(zones.length);
    const rootClassName = 'zonemanagement-zonelist';
    const downloadZonesRootClassName = `${rootClassName}__download-zones`;
    return (
        <div className={rootClassName} data-testid="zone-management-zone-list">
            <div
                className="zonemanagement-zonelist-header heading-1 _d-flex _ai-center"
                data-testid="zone-management-zone-list-header"
            >
                <Icon icon="zoneFilled" />
                <span className="heading-1">{t('zoneList.headerTitle')}</span>
                <TooltipButton
                    className={classNames(
                        downloadZonesRootClassName,
                        '_ml-auto _d-flex _jc-center _ai-center'
                    )}
                    onClick={downloadZones}
                    data-testid={downloadZonesRootClassName}
                >
                    <span>{t('zoneList.downloadZones')}</span>
                    <Icon icon="download2" size={IconSizes.S} color="meteor" />
                </TooltipButton>
            </div>
            <div
                className="zonemanagement-zonelist-body"
                data-testid="zone-management-zone-list-body"
            >
                {!isZonesAvailable && <NoZone />}
                {isZonesAvailable &&
                    zones.map((zone) => (
                        <ListItem
                            key={zone.id}
                            zone={zone}
                            assignedVehicles={
                                zoneVehicleAssociations[zone.id] || []
                            }
                        />
                    ))}
            </div>
        </div>
    );
};
