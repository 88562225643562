import { Feature as GeoJsonFeature, Point as GeoJsonPoint } from 'geojson';
import { ApiEquipment, Coordinates } from '~/api/types';
import { convertCoordinateToGeoJsonPoint } from '~/utils/location-utils';

export class Equipment {
    /**
     * The API source data
     */
    private readonly apiEquipment: ApiEquipment;

    // No constructor JSDoc to avoid duplicates in generated docs
    // https://github.com/jsdoc/jsdoc/issues/1775
    constructor(apiEquipment: ApiEquipment) {
        this.apiEquipment = apiEquipment;
    }

    /**
     * The equipment ID
     */
    get id(): string {
        return this.apiEquipment.id;
    }

    /**
     * The equipment name
     */
    get name(): string {
        return this.apiEquipment.name;
    }

    /**
     * The equipment type
     *
     * This value is defined by the client. Thus, it can vary from client-to-client.
     */
    get type(): string {
        return this.apiEquipment.type;
    }

    /**
     * The equipment location latitude
     */
    get lat(): number {
        return this.apiEquipment.lat || 0;
    }

    /**
     * The equipment location longitude
     */
    get lng(): number {
        return this.apiEquipment.lng || 0;
    }

    /**
     * The equipment location coordinates object
     */
    get coordinates(): Coordinates {
        return {
            lat: this.lat,
            lng: this.lng
        };
    }

    /**
     * The equipment location longitude
     */
    get geoJsonPoint(): GeoJsonFeature<GeoJsonPoint> {
        const featureProperties = {
            name: this.name
        };
        const featurePoint = convertCoordinateToGeoJsonPoint(
            this.coordinates,
            featureProperties
        );
        return featurePoint;
    }

    /**
     * The equipment status
     *
     * Represents the status regarding equipment's whereabouts in terms of operations.
     * For example, equipment was "dropped off and ready for use for another delivery".
     *
     * This value is defined by the client. Thus, it can vary from client-to-client.
     */
    get status(): string {
        return this.apiEquipment.status;
    }

    /**
     * The equipment condition states
     *
     * Represents the condition in which the equipment is in.
     * For example, the equipment can be "dirty" or "broken".
     *
     * A maximum of 5 items are allowed in this array.
     *
     * This values are defined by the client. Thus, it can vary from client-to-client.
     */
    get state(): string[] {
        return this.apiEquipment.state || [];
    }

    /**
     * Whether the equipment will coordinate all the attachments of other equipments
     *
     * When `TRUE`, the equipment is the main equipment that will coordinate all the attachments of other equipments.
     * When `FALSE`, the equipment will not have any attached equipment information.
     */
    get main(): boolean | undefined {
        return this.apiEquipment?.main;
    }

    /**
     * The equipment IDs attached to this equipment
     *
     * A maximum of 10 items are allowed in this array.
     */
    get attached(): string[] {
        return this.apiEquipment.attached || [];
    }

    /**
     * Any additional data associated with this equipment
     *
     * This will be a list of key-value pairs that can represent
     * any equipment-related information for the API user
     */
    get additionalData(): Record<string, unknown> {
        return this.apiEquipment.additionalData;
    }

    /**
     * The last updated date
     */
    get updatedAt(): string | undefined {
        return this.apiEquipment?.timestamp;
    }

    /**
     * Get the ID of the Customer.
     */
    get customerId(): string | undefined {
        return this.apiEquipment?.customerId;
    }

    /**
     * Get the first line of the customer's address.
     */
    get customerAddressLine1(): string | undefined {
        return this.apiEquipment?.customerAddressLine1;
    }

    /**
     * Get the city where the customer is located.
     */
    get customerCity(): string | undefined {
        return this.apiEquipment?.customerCity;
    }

    /**
     * Get the state or region where the customer is located.
     */
    get customerState(): string | undefined {
        return this.apiEquipment?.customerState;
    }

    /**
     * Get the postal code of the customer's address.
     */
    get customerPostalCode(): string | undefined {
        return this.apiEquipment?.customerPostalCode;
    }

    /**
     * Get the name of the customer.
     */
    get customerName(): string | undefined {
        return this.apiEquipment?.customerName;
    }

    /**
     * Get the dispatch zone associated with the customer.
     */
    get dispatchZone(): string | undefined {
        return this.apiEquipment?.dispatchZone;
    }

    /**
     * Get the area or district where the customer is located.
     */
    get area(): string | undefined {
        return this.apiEquipment?.area;
    }

    /**
     * Get the payerOfFreight of the equipment
     */
    get payerOfFreight(): string | undefined {
        return this.apiEquipment?.payerOfFreight;
    }

    /**
     * Get the reservation id of the equipment
     */
    get reservation(): string | undefined {
        return this.apiEquipment?.reservation;
    }

    /**
     * Get the carKind of the equipment.
     */
    get carKind(): string | undefined {
        return this.apiEquipment?.carKind;
    }

    /**
     * Get the chassis Id of the equipment
     *
     * Ideally, the Chassis ID should be available as a separate field in the equipment API response.
     * For the time being, we will extract this information from the attached array
     */
    get chassisId(): string {
        const [chassisId] = this.attached;
        return chassisId;
    }

    /**
     * Serializes this class back to JSON
     */
    toJSON(): ApiEquipment {
        return this.apiEquipment;
    }
}
