import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useIsFetching } from 'react-query';
import { useSelector, useDispatch } from 'react-redux';
import { LiveDriver } from '~/data-classes';
import {
    useToastContext,
    useBulkSuggest,
    useOnDemandDispatchAssignTasks,
    defaultBulkSuggestOptions,
    defaultBulkSuggestOrderBy,
    useIsolatedRoutes
} from '~/hooks';

import {
    addSelectedMapRoute,
    resetSelectedMapRoutes
} from '~/reducers/selectedMapRoutesSlice';

import {
    setIsOpenSelectedCardsDrawer,
    setIsOpenUnassignedTasksDrawer,
    setIsOpenSuggestDrawer
} from '~/reducers/mapDrawerSettingsSlice';
import { selectDate } from '~/reducers/selectedDateSlice';
import { setSelectedTaskIds } from '~/reducers/selectedTaskIdsSlice';

import { colorUtils } from '~/utils/color-utils';
import { idUtils } from '~/utils/id-utils';
import constants from '~/utils/constants';

import {
    Alert,
    ManageStopControlModal,
    Spinner,
    SuggestedDriverCard,
    TooltipButton
} from '~/ui';
import { selectLiveDrivers } from '~/reducers/liveDriversSlice';
import { resetSelectedMapStops } from '~/reducers/selectedMapStopsSlice';
import { useFitMapToBounds } from '~/components/MapPage/useFitMapToBounds';

function DispatchTasksControl({ selectedTaskIds, modalTitle, onClickTitle }) {
    const [suggestions, setSuggestions] = useState(null);
    const [taskIds, setTaskIds] = useState(selectedTaskIds);
    const [viewAll, setViewAll] = useState(false);
    const [dispatchData, setDispatchData] = useState(null);
    const selectedDate = useSelector(selectDate);
    const liveDrivers = useSelector(selectLiveDrivers);
    const dispatch = useDispatch();
    const { t } = useTranslation(['translation', 'taskManagement']);
    const isFetching = Boolean(useIsFetching());
    const { addToast } = useToastContext();

    const { isolateSingleRoute, isolateMultipleRoutes } = useIsolatedRoutes();
    const { setShouldFitToBounds } = useFitMapToBounds();

    const {
        isSuccess: suggestApiIsSuccess,
        isError: suggestApiIsError,
        error: suggestApiError
    } = useBulkSuggest(taskIds, defaultBulkSuggestOptions, {
        enabled: !!taskIds,
        onSuccess: _handleSuccessBulkSuggest
    });

    const { isError: assignTasksIsError, error: assignTasksError } =
        useOnDemandDispatchAssignTasks(dispatchData, {
            enabled: !!dispatchData,
            onSuccess: _handleSuccessAssignTasks
        });

    useEffect(() => {
        if (!suggestions?.length) return;
        const bulkSuggestDriverId = suggestions[0].driver.id;
        const suggestedDriver = Object.values(liveDrivers).find(
            (driver) => driver.id === bulkSuggestDriverId
        );
        if (!suggestedDriver) return;
        const suggestedLiveDriver = new LiveDriver(suggestedDriver);
        const { clientDriverId } = suggestedLiveDriver;
        _handleTopSuggestion(clientDriverId);
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [suggestions]);

    function _getListItems(data) {
        return data.map((suggestion, idx) => {
            const clientDriverId = idUtils.getCombinedId(
                suggestion.driver.client,
                suggestion.driver.id
            );
            const driverColors = colorUtils.getWebColorsForId(clientDriverId);
            const isBestDriver = idx === 0;
            return (
                <SuggestedDriverCard
                    key={clientDriverId}
                    suggestedDriverData={suggestion}
                    clientDriverId={clientDriverId}
                    driverColors={driverColors}
                    orderedBy={defaultBulkSuggestOrderBy}
                    isBestDriver={isBestDriver}
                    onClick={_handleClickDispatch}
                />
            );
        });
    }

    function _getError(errorData) {
        const responseError = errorData?.response?.data?.message;
        const errorMsg = responseError
            ? t(`UnassignedTasksControl.error.${responseError}`)
            : errorData.message;

        return (
            <Alert
                className="dispatch-tasks-control_error"
                variant="danger-dark"
                icon="warningFill2"
                iconSize="s"
                iconColor="mars"
            >
                {errorMsg}
            </Alert>
        );
    }

    function _handleTopSuggestion(clientDriverId) {
        dispatch(setIsOpenSuggestDrawer(true));
        dispatch(setIsOpenUnassignedTasksDrawer(true));
        dispatch(setIsOpenSelectedCardsDrawer(false));
        dispatch(addSelectedMapRoute(clientDriverId));
        isolateSingleRoute(true);
        setShouldFitToBounds(true);
    }

    function _handleClickViewAll() {
        setViewAll(true);
        isolateMultipleRoutes();
        if (suggestions) {
            const suggestedDrivers = suggestions.map((suggestion) => {
                return suggestion.driver.id;
            });

            Object.values(liveDrivers).forEach((driver) => {
                if (suggestedDrivers.includes(driver.id)) {
                    const suggestedLiveDriver = new LiveDriver(driver);
                    const { clientDriverId } = suggestedLiveDriver;
                    dispatch(addSelectedMapRoute(clientDriverId));
                    setShouldFitToBounds(true);
                }
            });
        }
    }

    function _handleClickDispatch(payload) {
        // trigger the assign tasks hook
        setDispatchData({ selectedDate, ...payload });
    }

    function _handleSuccessBulkSuggest(data) {
        // display view all with more than 1 result
        setViewAll(data.length <= 1);

        // set suggestions for display
        setSuggestions(data);

        // identify tasks for suggested drivers
        if (data.length > 0) {
            dispatch(setSelectedTaskIds(taskIds));
        }
    }

    function _handleSuccessAssignTasks(data) {
        // disable the bulk suggest hook
        setTaskIds();

        // clear suggestions
        setSuggestions();

        // set success toast message
        const key = data.payload.isLiveDriver
            ? 'taskManagement:assignTask.dispatchConfirmation'
            : 'taskManagement:assignTask.assignConfirmation';
        addToast({
            message: t(key, { count: data.payload.tasksToDispatch.length })
        });

        // closes the ODD and suggest drawers and control
        dispatch(setIsOpenUnassignedTasksDrawer(false));
        dispatch(setIsOpenSuggestDrawer(false));

        // resets selected map routes so the map is cleared when user is redirected
        dispatch(resetSelectedMapRoutes());
        dispatch(resetSelectedMapStops());
        onClickTitle();
    }

    function _resetStopControlModal() {
        dispatch(setIsOpenSuggestDrawer(false));
        dispatch(resetSelectedMapRoutes());
        onClickTitle();
    }

    return (
        <ManageStopControlModal
            className="dispatch-tasks-control"
            modalTitle={modalTitle}
            onClickTitle={_resetStopControlModal}
            data-testid="dispatch-tasks-control"
        >
            {isFetching && (
                <div className="dispatch-tasks-control_loading _d-flex _jc-center _ai-center">
                    <Spinner
                        sx={{ width: '2rem', height: '2rem' }}
                        color="ocean"
                    />
                </div>
            )}
            {!isFetching && suggestApiIsSuccess && suggestions && (
                <>
                    {suggestions.length > 0 && (
                        <>
                            <div className="dispatch-tasks-control_text _text-3-alt">
                                {t(
                                    'UnassignedTasksControl.title.suggested_routes'
                                )}
                            </div>
                            <div className="dispatch-tasks-control_list _text-3-alt">
                                {_getListItems(
                                    viewAll ? suggestions : [suggestions[0]]
                                )}
                            </div>
                        </>
                    )}
                    {!suggestions.length &&
                        _getError({
                            message: t(
                                'UnassignedTasksControl.error.no_drivers'
                            )
                        })}
                    {!viewAll && (
                        <TooltipButton
                            className="dispatch-tasks-control_view-all _text-3 button_clear"
                            onClick={_handleClickViewAll}
                        >
                            {t('UnassignedTasksControl.button.viewAll')}
                        </TooltipButton>
                    )}
                    {viewAll && (
                        <div className="dispatch-tasks-control_footer _text-3-alt">
                            <Trans i18nKey="UnassignedTasksControl.title.suggestions_info">
                                <Link to={constants.url.TASK_MANAGEMENT} />
                            </Trans>
                        </div>
                    )}
                </>
            )}
            {!isFetching && suggestApiIsError && _getError(suggestApiError)}
            {!isFetching && assignTasksIsError && _getError(assignTasksError)}
        </ManageStopControlModal>
    );
}

export default DispatchTasksControl;
