import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { setSelectedTaskRowId } from '~/reducers/selectedTaskRowIdSlice';
import { resetSelectedMapStops } from '~/reducers/selectedMapStopsSlice';

import { isAssignmentLate } from '~/utils/assignment-utils';

import {
    ManageStopControlMenu,
    RouteCardAddress,
    RouteCardEta,
    RouteCardLabels,
    RouteCardTitle,
    StopModalMenuButton,
    RouteCardMetric,
    TooltipButton,
    RouteCardText
} from '~/ui';
import './manage-stop-control-single.scss';

const ROOT_CLASS_NAME = 'managestopcontrol-single';

function ManageStopControlSingle({
    stopData,
    children,
    className,
    footerButtonTitle,
    footerButtonIcon,
    onClickFooterButton,
    ...extra
}) {
    const dataTestId = extra['data-testid'] || ROOT_CLASS_NAME;

    const dispatch = useDispatch();
    const {
        delay,
        delayFlag,
        taskId,
        size,
        location: { addressLine1 } = {},
        stopName,
        labels
    } = stopData || {};
    const { t } = useTranslation('translation');

    const isLateStatus = useMemo(() => {
        const isLiveStopLate = isAssignmentLate(delay);
        const isPlanStopLate = isAssignmentLate(delayFlag);
        return isPlanStopLate || isLiveStopLate;
    }, [delay, delayFlag]);

    function _getClassName() {
        const defaultClassName = `managestopcontrol_single _d-flex _fd-column`;
        return classNames(defaultClassName, className);
    }

    function _getStopMetric() {
        return (
            <RouteCardMetric
                value={t('PlanStopsControl.title.late_stop')}
                iconSize="s"
                iconColor="mars"
                icon="clockFill2"
                data-testid={`${dataTestId}-late`}
            />
        );
    }

    const handleClickViewMore = () => {
        // strip the `pickup` or `delivery` prefix in cases of a two-part task being selected
        const rootTaskId = taskId.replace(/(pickup|delivery)_/, '');

        dispatch(resetSelectedMapStops());
        dispatch(setSelectedTaskRowId(rootTaskId));
    };

    if (!stopData) return null;

    return (
        <div className={_getClassName()} data-testid={dataTestId}>
            <div className="manage-stop-control-multiple__main">
                {isLateStatus && _getStopMetric()}
                <RouteCardTitle
                    className="_text-2"
                    data-testid={`${dataTestId}-title`}
                >
                    {stopName}
                </RouteCardTitle>
                <RouteCardAddress
                    address={addressLine1}
                    icon="pin"
                    data-testid={`${dataTestId}-address`}
                />
                <RouteCardEta
                    stopData={stopData}
                    icon="clock"
                    data-testid={`${dataTestId}-time`}
                />
                <RouteCardText
                    className="_mb-3"
                    icon="volume"
                    data-testid={`${dataTestId}-volume`}
                >
                    {size}
                </RouteCardText>
                <RouteCardLabels
                    labels={labels}
                    icon="union"
                    data-testid={`${dataTestId}-labels`}
                />
                <TooltipButton
                    className="managestopcontrol_view-more"
                    variant={TooltipButton.variants.LINK}
                    onClick={handleClickViewMore}
                    data-testid={`${dataTestId}-viewmore`}
                >
                    {t('Collapsible.view_more')}
                </TooltipButton>
            </div>
            {children}
            {footerButtonTitle && (
                <ManageStopControlMenu
                    className="managestopcontrol_footer"
                    data-testid={`${dataTestId}-footer`}
                >
                    <StopModalMenuButton
                        buttonIcon={footerButtonIcon}
                        buttonText={footerButtonTitle}
                        onClick={onClickFooterButton}
                        data-testid={`${dataTestId}-footer_button`}
                    />
                </ManageStopControlMenu>
            )}
        </div>
    );
}

export { ManageStopControlSingle };
