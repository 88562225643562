import { useSelector } from 'react-redux';
import { selectMainClient } from '~/reducers/mainClientSlice';
import { FeatureFlag } from '~/utils/feature-flags-utils';
import { useFeatureFlag } from '../useFeatureFlag';
import { UseIntermodalFeatures } from './types';

export const useIntermodalFeatures = (): UseIntermodalFeatures => {
    const mainClient = useSelector(selectMainClient);
    const {
        enableLiveDispatch: isEnabledLiveDispatch,
        enableExternalTaskTypes: isEnabledExternalTaskTypes,
        enableGapInRoute: isEnabledGapInRoute,
        enableResetTasks: isEnabledResetTasks,
        enableOperationalStops: isEnabledOperationalStops,
        enableEndOfShift: isEnabledEndOfShift,
        enableUnarriveStops: isEnabledUnarriveStops,
        enableRescheduleStops: isEnabledRescheduleStops,
        enableTaskEquipmentField = {},
        carKind = [],
        externalTaskTypes = {}
    } = mainClient?.intermodal || {};

    const {
        pickup = [],
        delivery = [],
        twoPart = [],
        operationalStop = []
    } = externalTaskTypes;

    const {
        equipmentId: isEnabledFieldEquipmentId,
        carKind: isEnabledFieldCarKind,
        emptyOrderNumber: isEnabledFieldEmptyOrder,
        reservation: isEnabledFieldReservation,
        chassisId: isEnabledFieldChassisId,
        externalTaskType: isEnabledFieldExternalTaskType,
        showToDriver: isEnabledFieldShowToDriver
    } = enableTaskEquipmentField;

    const isFeatureEnabledExternalTaskTypes = useFeatureFlag(
        FeatureFlag.PREDEFINED_EXTERNAL_TASK_TYPES
    );
    const isFeatureEnabledResetTasks = useFeatureFlag(FeatureFlag.RESET_TASKS);
    const isFeatureEnabledUnarriveStops = useFeatureFlag(
        FeatureFlag.UNARRIVE_STOPS
    );

    const enableExternalTaskTypes = Boolean(
        isEnabledExternalTaskTypes || isFeatureEnabledExternalTaskTypes
    );
    const enableResetTasks = Boolean(
        isEnabledResetTasks || isFeatureEnabledResetTasks
    );
    const enableUnarriveStops = Boolean(
        isEnabledUnarriveStops || isFeatureEnabledUnarriveStops
    );

    return {
        carKind,
        externalTaskTypes: { pickup, delivery, twoPart, operationalStop },
        enableLiveDispatch: Boolean(isEnabledLiveDispatch),
        enableExternalTaskTypes,
        enableGapInRoute: Boolean(isEnabledGapInRoute),
        enableResetTasks,
        enableOperationalStops: Boolean(isEnabledOperationalStops),
        enableEditReservation: Boolean(isEnabledFieldReservation),
        enableEditEquipmentId: Boolean(isEnabledFieldEquipmentId),
        enableEditExternalTaskType: Boolean(isEnabledFieldExternalTaskType),
        enableEditCarKind: Boolean(isEnabledFieldCarKind),
        enableEditChassisId: Boolean(isEnabledFieldChassisId),
        enableEditEmptyOrder: Boolean(isEnabledFieldEmptyOrder),
        enableEditShowToDriver: Boolean(isEnabledFieldShowToDriver),
        enableEndOfShift: Boolean(isEnabledEndOfShift),
        enableUnarriveStops,
        enableRescheduleStops: Boolean(isEnabledRescheduleStops)
    };
};
