import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { replaceSelectedMapStop } from '~/reducers/selectedMapStopsSlice';
import { selectIsOpenUnassignedTasksDrawer } from '~/reducers/mapDrawerSettingsSlice';
import { selectMainClient } from '~/reducers/mainClientSlice';

import useReassignSetup from '~/components/MapPage/ReassignModal/useReassignSetup';
import { useMapUtils } from '~/hooks';

import { mapPlanStopUtils } from '~/utils/map';

import { Button, Icon, Text, useModal } from '~/ui';

import { ResequenceModalStopList } from '~/components/MapPage/ResequenceModal';
import TripListItem from '~/components/MapPage/ReassignModal/TripListItem';
import MultiSearchableDropdown from '~/components/MapPage/ReassignModal/MultiSearchableDropdown';
import { LockedRouteWarning } from '~/components/MapPage/ReassignModal/LockedRouteWarning';
import { ModalSize } from '~/ui/components/Modal/types';

import { verifyIfRouteLocked } from './reassignUtils';

export const ReassignModalContent = ({
    routes,
    selectedStops,
    stopIdPropertyName
}) => {
    const dispatch = useDispatch();
    const isOpenUnassignedTasksDrawer = useSelector(
        selectIsOpenUnassignedTasksDrawer
    );
    const {
        showModal: showLockedWarning,
        hideModal: hideLockedWarning,
        modal: LockedRouteWarningModal
    } = useModal();

    const mainClient = useSelector(selectMainClient);
    const { driverPrivacy } = mainClient?.preferences || {};

    const [selectedRouteId, setSelectedRouteId] = useState(null);
    const [selectedTripId, setSelectedTripId] = useState(null);
    const [dragStartIndex, setDragStartIndex] = useState(null);
    const { t } = useTranslation('translation');
    const { reassignStops, showTrips, routeTrips } = useReassignSetup({
        routes,
        selectedRouteId,
        selectedStops,
        selectedTripId
    });
    const { isPlanRouteMode } = useMapUtils();
    const [firstSelectedStop] = selectedStops;
    const selectedRoute = useMemo(() => {
        return routes.find(({ routeId }) => selectedRouteId === routeId);
    }, [selectedRouteId, routes]);

    const selectableRoutes = useMemo(() => {
        const otherRoutes = routes.filter(
            ({ clientRouteId }) =>
                clientRouteId !== firstSelectedStop?.clientRouteId
        );
        return _.sortBy(otherRoutes);
    }, [routes, firstSelectedStop]);

    useEffect(() => {
        setSelectedTripId(null);
    }, [selectedRouteId]);

    const handleDrop = useCallback(
        (e, index) => {
            dispatch(
                replaceSelectedMapStop(
                    mapPlanStopUtils.processStopsReorder(
                        e,
                        selectedStops,
                        stopIdPropertyName,
                        dragStartIndex,
                        index,
                        isOpenUnassignedTasksDrawer
                    )
                )
            );
        },
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
        [dispatch, dragStartIndex, selectedStops, stopIdPropertyName]
    );

    const isButtonDisabled =
        !selectedRouteId ||
        (showTrips && !selectedTripId) ||
        !selectedStops.length;

    const onClickReassign = () => {
        const isLocked = verifyIfRouteLocked(selectedRoute);
        if (isPlanRouteMode && isLocked) {
            showLockedWarning();
            return;
        }
        reassignStops();
    };

    const onLockedRouteReassignConfirm = () => {
        hideLockedWarning();
        reassignStops();
    };

    return (
        <>
            <div className="reassignmodal-content">
                <div className="stop-list">
                    {selectedStops.map((selectedStop, index) => (
                        <ResequenceModalStopList
                            key={selectedStop[stopIdPropertyName]}
                            stopIdPropertyName={stopIdPropertyName}
                            selectedStop={selectedStop}
                            handleDragStart={() => setDragStartIndex(index)}
                            handleDragOver={(e) => e.preventDefault()}
                            handleDrop={(e) => handleDrop(e, index)}
                        />
                    ))}
                </div>
                <div className="reassignmodal-content-search">
                    <div
                        className="_d-flex _fd-column reassign-modal__route-search"
                        data-testid="reassign-modal-route-selection"
                    >
                        <span>{t('ReassignModal.Search.route_header')}</span>
                        <MultiSearchableDropdown
                            placeholder={t(
                                'ReassignModal.Search.input_placeholder'
                            )}
                            items={selectableRoutes.map(
                                ({ routeId, id, routeName, vehicleEid }) => {
                                    return {
                                        id: routeId || id,
                                        name: driverPrivacy
                                            ? vehicleEid
                                            : routeName
                                    };
                                }
                            )}
                            showInList={({ name = '' } = {}) => <>{name}</>}
                            formatWhenSelected={({ name = '' } = {}) => name}
                            searchableKeys={['name']}
                            selectedId={selectedRouteId}
                            onItemSelect={setSelectedRouteId}
                        />
                    </div>
                    {showTrips && (
                        <div
                            className="_d-flex _fd-column reassign-modal__trip-search"
                            data-testid="reassign-modal-trip-selection"
                        >
                            <span>{t('ReassignModal.Search.trip_header')}</span>
                            <MultiSearchableDropdown
                                placeholder={t(
                                    'ReassignModal.Search.trip_input_placeholder'
                                )}
                                items={routeTrips.map((trip, index) => {
                                    const { routeId, stats } = trip;
                                    return {
                                        // @TODO add departure time once https://wisesys.atlassian.net/browse/RP-1061 is done
                                        id: routeId,
                                        name: t('TripName', {
                                            number: index + 1
                                        }),
                                        stats
                                    };
                                })}
                                searchableKeys={['name']}
                                selectedId={selectedTripId}
                                showInList={({
                                    name = '',
                                    stats = {}
                                } = {}) => {
                                    const { clientId } = routes.find(
                                        (route) =>
                                            route.routeId === selectedRouteId
                                    );
                                    return (
                                        <TripListItem
                                            name={name}
                                            stats={stats}
                                            clientId={clientId}
                                        />
                                    );
                                }}
                                formatWhenSelected={({ name = '' } = {}) =>
                                    name
                                }
                                onItemSelect={setSelectedTripId}
                            />
                        </div>
                    )}
                    <Button
                        onClick={onClickReassign}
                        disabled={isButtonDisabled}
                        className="reassignmodal-content-button"
                    >
                        <div className="_ai-center _jc-center">
                            <Icon icon="iconRouteFill" color="white" />
                            <Text
                                className="_text-3"
                                color="white"
                                variant="12-normal"
                            >
                                {t('PlanStopsControl.button.reassign')}
                            </Text>
                        </div>
                    </Button>
                </div>
                <Text
                    className="_text-3"
                    color="galaxy-500"
                    variant="12-normal"
                >
                    <Text color="meteor">
                        {t('ReassignModal.Content.proTipLabel')}
                    </Text>
                    &nbsp;{t('ReassignModal.Content.proTipText')}
                </Text>
            </div>
            <LockedRouteWarningModal
                title={t('reassignRoutes:lockedRouteWarning.warningTitle')}
                variant={ModalSize.SMALL_DIALOGUE}
                onHide={() => {
                    hideModal();
                }}
                hasCloseButton
            >
                <LockedRouteWarning
                    onConfirm={onLockedRouteReassignConfirm}
                    onCancel={hideLockedWarning}
                    route={selectedRoute}
                />
            </LockedRouteWarningModal>
        </>
    );
};
