import React, { ReactNode } from 'react';

import './CollapsibleListControlled.scss';

type Props = {
    isExpanded?: boolean;
    children: ReactNode;
};

const CollapsibleListControlled = ({
    children,
    isExpanded
}: Props): JSX.Element => {
    return (
        <>
            {isExpanded && (
                <div
                    className="collapsible-list _fd-column"
                    data-testid="collapsible-list"
                >
                    {children}
                </div>
            )}
        </>
    );
};

export default CollapsibleListControlled;
