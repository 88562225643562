import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
    ManageStopControlMenu,
    ManageStopControlSingle,
    StopModalMenuButton
} from '~/ui';

import { MarkEndOfShiftControl } from '~/components/MapPage/MarkEndOfShiftControl';
import {
    useIntermodalFeatures,
    useOnDemandDispatchUnassignTasks,
    useToastContext
} from '~/hooks';
import { selectMainClient } from '~/reducers/mainClientSlice';
import constants from '~/utils/constants';
import { useEndOfShiftCallbacks } from '~/components/MapPage/MarkEndOfShiftControl/useEndOfShiftCallbacks';

function LiveSingleStopControl({
    stopData,
    footerButtonTitle,
    footerButtonIcon,
    onClickFooterButton,
    onSwitchView,
    disableResequencing = false
}) {
    const { isCompleted } = stopData || {};

    const { hasLastStopInShift, handleRemoveShiftEnd } =
        useEndOfShiftCallbacks(stopData);
    const { t } = useTranslation('translation');
    const { cancelTasks, unassignTasks } = useOnDemandDispatchUnassignTasks();
    const { addToast } = useToastContext();

    const { enableEndOfShift } = useIntermodalFeatures();

    const mainClient = useSelector(selectMainClient);

    const showResequenceButton = !isCompleted;
    const showUnassignButton = !isCompleted;
    const showCancelButton =
        mainClient?.preferences?.showDispatchCancelButton && !isCompleted;
    const showMarkEndOfShiftButton = enableEndOfShift;

    const handleClickLiveStopUnassign = () => {
        unassignTasks({
            onSuccess: (data) => {
                const count = JSON.parse(data.config.data).taskIds.length;
                addToast({
                    message: t('PlanStopsControl.toast.onSuccessUnassign', {
                        count
                    }),
                    variant: 'success'
                });
                if (hasLastStopInShift) {
                    handleRemoveShiftEnd(stopData);
                }
            },
            onError: (error) => {
                if (error?.data || error?.response?.data) {
                    console.error(error);
                }
                addToast({
                    message: t('PlanStopsControl.toast.onErrorUnassign', {
                        count: 1
                    }),
                    variant: 'error'
                });
            }
        });
    };

    const handleClickLiveStopCancel = () => {
        cancelTasks({
            onSuccess: (data) => {
                const count = JSON.parse(data.config.data).taskIds.length;
                addToast({
                    message: t('PlanStopsControl.toast.onSuccessCancel', {
                        count
                    }),
                    variant: 'success'
                });
            },
            onError: (error) => {
                if (error?.data || error?.response?.data) {
                    console.error(error);
                }
                addToast({
                    message: t('PlanStopsControl.toast.onErrorCancel', {
                        count: 1
                    }),
                    variant: 'error'
                });
            }
        });
    };

    return (
        <ManageStopControlSingle
            className="live-single-stop-control"
            stopData={stopData}
            footerButtonTitle={footerButtonTitle}
            footerButtonIcon={footerButtonIcon}
            onClickFooterButton={onClickFooterButton}
        >
            <ManageStopControlMenu>
                {showResequenceButton && (
                    <StopModalMenuButton
                        buttonIcon="iconReorder"
                        buttonText={t('PlanStopsControl.button.resequence')}
                        onClick={() =>
                            onSwitchView(
                                constants.stopsControl.views.RESEQUENCE
                            )
                        }
                        buttonStopText={t('PlanStopsControl.button.stopText', {
                            stopIndex: stopData.stopNumber
                        })}
                        disabled={disableResequencing}
                        showButtonArrow
                        data-testid="button-resequence"
                    />
                )}
                {showUnassignButton && (
                    <StopModalMenuButton
                        onClick={handleClickLiveStopUnassign}
                        buttonIcon="unassign"
                        buttonText={t('PlanStopsControl.button.unassign')}
                        data-testid="button-live-stop-unassign"
                    />
                )}
                {showCancelButton && (
                    <StopModalMenuButton
                        onClick={handleClickLiveStopCancel}
                        buttonIcon="iconRouteless"
                        buttonText={t('PlanStopsControl.button.cancel')}
                        data-testid="button-cancel"
                    />
                )}
                {showMarkEndOfShiftButton && (
                    <MarkEndOfShiftControl stopData={stopData} />
                )}
            </ManageStopControlMenu>
        </ManageStopControlSingle>
    );
}

export default LiveSingleStopControl;
