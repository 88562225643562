import React, {
    useRef,
    useState,
    useMemo,
    useCallback,
    useEffect
} from 'react';
import { PlanMapDisplayContext } from './PlanMapDisplayContext';
import useCompletedModeMapMarkers from '~/components/MapPage/PlanMap/useCompletedModeMapMarkers';
import useDispatchedModeMapMarkers from '~/components/MapPage/PlanMap/useDispatchedModeMapMarkers';
import usePlannedModeMapMarkers from '~/components/MapPage/PlanMap/usePlannedModeMapMarkers';
import useDepots from '~/components/MapPage/PlanMap/useDepots';
import useSuperClusters from '~/components/MapPage/PlanMap/useSuperClusters';
import { useIsolatedRoutes, useMapUtils } from '~/hooks';
import constants from '~/utils/constants';
import { Coordinates } from '~/api/types';
import { useSelector } from 'react-redux';
import { selectShowUnassignedTasks } from '~/reducers/mapSettingsSlice';
import { ConfigurableMapRouteMode } from '~/reducers/mapSettingsSlice/types';
import { useUnassignedSuperClusters } from '../useUnassignedSuperClusters';
import { useEquipmentClusters } from '../useEquipmentClusters';
import { useLiveStopsSuperClusters } from '~/components/MapPage/PlanMap/useLiveStopsSuperClusters';

type Props = {
    children: React.ReactChild;
};

const { mapRouteModes } = constants;

export const PlanMapDisplayContextProvider = ({
    children
}: Props): JSX.Element => {
    const routeLevelCoordinatesRef = useRef([]);
    const stopLevelCoordinatesRef = useRef([]);
    const driverCoordinatesRef = useRef([]);
    const completedRoutesCoordinatesRef = useRef([]);

    const [markerPopup, setMarkerPopup] = useState<JSX.Element | null>(null);
    const [mapGestureHandling, setMapGestureHandling] = useState<string>(
        constants.mapOptionSettings.GESTURE_HANDLING_AUTO
    );
    const [hoveredRouteMarker, setHoveredRouteMarker] =
        useState<string | null>(null);

    const enableMapGestureHandling = useCallback(() => {
        setMapGestureHandling(
            constants.mapOptionSettings.GESTURE_HANDLING_AUTO
        );
    }, [setMapGestureHandling]);

    const disableMapGestureHandling = useCallback(() => {
        setMapGestureHandling(constants.mapOptionSettings.GESTURE_HANDLING_OFF);
    }, [setMapGestureHandling]);

    const { hasIsolatedRoutes } = useIsolatedRoutes();
    const { mapRouteMode, isRoutesMode, mapMarkerMode } = useMapUtils();
    const { superClusters } = useSuperClusters();
    const { superClusters: unassignedSuperClusters } =
        useUnassignedSuperClusters();
    const { superClusters: equipmentSuperClusters } = useEquipmentClusters();
    const { depots, depotMarkers: depotMarkersEffect } = useDepots();
    const { superClusters: liveStopsSuperClusters } =
        useLiveStopsSuperClusters();

    const {
        routeMarkers: plannedRouteMarkers,
        routeLines: plannedRouteLines,
        routeStopMarkers: plannedRouteStopMarkers,
        polygons: plannedRoutePolygons
    } = usePlannedModeMapMarkers({
        routeLevelCoordinatesRef,
        stopLevelCoordinatesRef,
        superClusters,
        unassignedSuperClusters
    });

    const {
        depotStopMarkers: completedDepotStopMarkers,
        routeMarkers: completedRouteMarkers,
        routeStopMarkers: completedRouteStopMarkers,
        routeLines: completedRouteLines
    } = useCompletedModeMapMarkers({
        stopLevelCoordinatesRef,
        completedRoutesCoordinatesRef
    });

    const {
        routeMarkers: dispatchedRouteMarkers,
        routeStopMarkers: dispatchedRouteStopMarkers,
        depotStopMarkers: dispatchedDepotStopMarkers,
        routeLines: dispatchedRouteLines,
        selectedRouteLines: dispatchedSelectedRouteLines,
        equipmentMarkers: dispatchedEquipmentMarkers
    } = useDispatchedModeMapMarkers({
        stopLevelCoordinatesRef,
        driverCoordinatesRef,
        unassignedSuperClusters,
        equipmentSuperClusters,
        liveStopsSuperClusters
    });

    const showUnassignedTasks = useSelector(
        selectShowUnassignedTasks(
            mapRouteMode as unknown as ConfigurableMapRouteMode
        )
    );

    const [markers, setMarkers] = useState<JSX.Element[]>([]);
    const [routeLines, setRouteLines] = useState<JSX.Element[]>([]);
    const [mapSourceCoordinates, setMapSourceCoordinates] = useState<
        Coordinates[]
    >([]);
    const [equipmentMarkers, setEquipmentMarkers] = useState<JSX.Element[]>([]);
    const [routePolygons, setRoutePolygons] = useState<JSX.Element[]>([]);

    useEffect(() => {
        setHoveredRouteMarker(null);
    }, [mapMarkerMode]);

    useEffect(() => {
        if (mapRouteMode !== mapRouteModes.DISPATCHED) return;
        if (hasIsolatedRoutes || showUnassignedTasks) {
            setMarkers(dispatchedRouteStopMarkers);
            setRouteLines(dispatchedSelectedRouteLines);
            setMapSourceCoordinates(stopLevelCoordinatesRef.current);
        } else {
            setMarkers(dispatchedRouteMarkers);
            setRouteLines(dispatchedRouteLines);
            setMapSourceCoordinates(driverCoordinatesRef.current);
        }
        setEquipmentMarkers(dispatchedEquipmentMarkers);
    }, [
        mapRouteMode,
        hasIsolatedRoutes,
        showUnassignedTasks,
        dispatchedRouteMarkers,
        dispatchedRouteStopMarkers,
        dispatchedRouteLines,
        dispatchedSelectedRouteLines,
        dispatchedEquipmentMarkers
    ]);

    useEffect(() => {
        if (mapRouteMode !== mapRouteModes.COMPLETED) return;
        if (hasIsolatedRoutes) {
            setMarkers(completedRouteStopMarkers);
            setRouteLines(completedRouteLines);
            setMapSourceCoordinates(stopLevelCoordinatesRef.current);
        } else {
            setMarkers(completedRouteMarkers);
            setRouteLines([]);
            setMapSourceCoordinates(completedRoutesCoordinatesRef.current);
        }
    }, [
        mapRouteMode,
        hasIsolatedRoutes,
        completedRouteMarkers,
        completedRouteStopMarkers,
        completedRouteLines
    ]);

    useEffect(() => {
        if (mapRouteMode !== mapRouteModes.PLAN) return;
        if (isRoutesMode) {
            setMarkers(plannedRouteMarkers);
            setRouteLines([]);
            setMapSourceCoordinates(routeLevelCoordinatesRef.current);
        } else {
            setMarkers(plannedRouteStopMarkers);
            setRouteLines(plannedRouteLines);
            setMapSourceCoordinates(stopLevelCoordinatesRef.current);
        }
        setRoutePolygons(plannedRoutePolygons);
    }, [
        mapRouteMode,
        isRoutesMode,
        plannedRouteMarkers,
        plannedRouteLines,
        plannedRouteStopMarkers,
        plannedRoutePolygons
    ]);

    const depotMarkers = useMemo(() => {
        return depotMarkersEffect.length
            ? depotMarkersEffect
            : [
                  ...(dispatchedDepotStopMarkers ?? []),
                  ...(completedDepotStopMarkers ?? [])
              ];
    }, [
        depotMarkersEffect,
        dispatchedDepotStopMarkers,
        completedDepotStopMarkers
    ]);

    return (
        <PlanMapDisplayContext.Provider
            value={{
                markers,
                routeLines,
                mapSourceCoordinates,
                equipmentMarkers,
                routePolygons,
                hoveredRouteMarker,
                setHoveredRouteMarker,
                markerPopup,
                setMarkerPopup,
                mapGestureHandling,
                disableMapGestureHandling,
                enableMapGestureHandling,
                routeLevelCoordinatesRef,
                stopLevelCoordinatesRef,
                completedRoutesCoordinatesRef,
                driverCoordinatesRef,
                depots,
                depotMarkers,
                superClusters
            }}
        >
            {children}
        </PlanMapDisplayContext.Provider>
    );
};
