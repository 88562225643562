import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Icon, Tooltip } from '~/ui';
import { RouteCardMetricProps } from './types';
import { RouteCardMetricValuesWithUnits } from './RouteCardMetricValuesWithUnits';
import './RouteCardMetric.scss';

const rootClassName = 'routecard-metric';
const valueDefaultClassName = `${rootClassName}__value`;
const unitsDefaultClassName = `${rootClassName}__units`;

function RouteCardMetric({
    className,
    value,
    valueColor,
    maxValue,
    showMaxValue = false,
    icon,
    iconSize = 'xs',
    iconColor,
    icons = [],
    metric,
    tooltipText,
    isOverCapacity,
    valuesWithUnits,
    ...extra
}: RouteCardMetricProps) {
    const { t } = useTranslation('translation');
    const isValuesWithUnits = Boolean(valuesWithUnits?.length);

    function getClassName() {
        const defaultClassName = 'routecard-metric _d-flex';
        const conditionalClasses = {
            [`routecard-metric_${valueColor}`]: valueColor,
            '_ai-baseline': isValuesWithUnits,
            '_ai-center': !isValuesWithUnits
        };
        return classNames(defaultClassName, conditionalClasses, className);
    }

    const valueClassName = classNames(valueDefaultClassName, {
        [`${valueDefaultClassName}--over-capacity`]: isOverCapacity
    });

    const unitsClassName = classNames(unitsDefaultClassName, {
        [`${unitsDefaultClassName}--over-capacity`]: isOverCapacity
    });

    function getIcons() {
        if (icons && icons.length > 0) {
            return icons.map((item, idx) => {
                const {
                    icon: itemIcon,
                    iconSize: itemIconSize,
                    iconColor: itemIconColor
                } = item;
                const key = `${itemIcon}-${itemIconSize}-${itemIconColor}-${idx}`;
                if (!itemIcon) return null;
                return (
                    <Icon
                        key={key}
                        icon={itemIcon}
                        size={itemIconSize}
                        color={itemIconColor}
                        data-testid={
                            extra['data-testid']
                                ? `${extra['data-testid']}_icon`
                                : 'routecard-metric_icon'
                        }
                    />
                );
            });
        }

        if (icon) {
            return (
                <Icon
                    icon={icon}
                    size={iconSize}
                    color={iconColor}
                    data-testid={
                        extra['data-testid']
                            ? `${extra['data-testid']}_icon`
                            : 'routecard-metric_icon'
                    }
                />
            );
        }
    }

    return (
        <div
            className={getClassName()}
            data-testid={extra['data-testid'] || 'routecard-metric'}
        >
            <Tooltip
                content={tooltipText}
                placement="top"
                arrowProps={{
                    style: {
                        position: 'absolute',
                        bottom: '-0.7rem',
                        left: '50%',
                        width: '0',
                        height: '0',
                        borderLeft: '0.8rem solid transparent',
                        borderRight: '0.8rem solid transparent',
                        borderTop: '1rem solid var(--color-galaxy-700)'
                    }
                }}
            >
                <div
                    className={getClassName()}
                    data-testid="routecard-metric-tooltip"
                >
                    {(icon || icons) && getIcons()}
                    {value && (
                        <span
                            className={valueClassName}
                            data-testid={
                                extra['data-testid']
                                    ? `${extra['data-testid']}_value`
                                    : 'routecard-metric_value'
                            }
                        >
                            {maxValue
                                ? t('valueOfMaxValue', { value, maxValue })
                                : value}
                        </span>
                    )}
                    <RouteCardMetricValuesWithUnits
                        unitsClassName={unitsClassName}
                        valuesClassName={valueClassName}
                        valuesWithUnits={valuesWithUnits}
                        maxValue={
                            showMaxValue && maxValue ? maxValue : undefined
                        }
                    />
                </div>
            </Tooltip>
        </div>
    );
}

export default RouteCardMetric;
