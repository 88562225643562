import React, { useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
    mapStopIdsToLiveStops,
    useResequenceDriverTasks
} from '~/hooks/useResequenceDriverTasks';
import { useMapUtils } from '~/hooks';
import {
    resetSelectedMapStops,
    selectSelectedMapStops
} from '~/reducers/selectedMapStopsSlice';
import { selectDispatchedwithCompletedDrivers } from '~/reducers/liveDriversSlice';

import { ManageStopControl } from '~/ui';
import { ResequenceModal } from '~/components/MapPage/ResequenceModal';
import LiveSingleStopControl from './LiveSingleStopControl';
import LiveMultiStopsControl from './LiveMultiStopsControl';

import constants from '~/utils/constants';

const { views } = constants.stopsControl;

const STOP_ID_PROPERTY_NAME = 'id';

function LiveStopsControl() {
    const dispatch = useDispatch();
    const dispatchedDrivers = useSelector(selectDispatchedwithCompletedDrivers);
    const selectedMapStopsIds = useSelector(selectSelectedMapStops);
    const [activeView, setActiveView] = useState(views.MAIN);
    const { resequenceDriverTasks, isResequenceAllowed } =
        useResequenceDriverTasks();
    const { mapRouteMode } = useMapUtils();

    const { t } = useTranslation('translation');

    const isMultiStopsSelected = selectedMapStopsIds.length > 1;

    const isViewMain = activeView === views.MAIN;
    const isViewResequence = activeView === views.RESEQUENCE;
    const isViewMultiStopControl = isViewMain && isMultiStopsSelected;
    const isViewSingleStopControl = isViewMain && !isMultiStopsSelected;

    const {
        driver,
        firstSelectedStop,
        selectedStops = [],
        unselectedStops = []
    } = useMemo(() => {
        return mapStopIdsToLiveStops({
            dispatchedDrivers,
            selectedMapStopsIds
        });
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [dispatchedDrivers, mapRouteMode, selectedMapStopsIds]);

    const isTwoPartTask = useMemo(() => {
        if (selectedStops.length === 2) {
            const { taskId: firstStopTaskId } = selectedStops[0];
            const { taskId: secondStopTaskId } = selectedStops[1];
            return firstStopTaskId === secondStopTaskId;
        }
        return false;
    }, [selectedStops]);

    const deselectButtonClickHandler = useCallback(() => {
        dispatch(resetSelectedMapStops());
    }, [dispatch]);

    const onCloseHandler = useCallback(() => setActiveView(views.MAIN), []);

    const onResequence = useCallback(
        (selectedStopId, isInsertAfter) => {
            const selectedStop = driver?.schedule.find(
                ({ id }) => id === selectedStopId
            );

            if (!selectedStop) {
                console.warn('Stop not selected');
                return;
            }

            const { driverStopNumber } = selectedStop;

            resequenceDriverTasks({
                newDriverStopNumber: driverStopNumber,
                isInsertAfter
            });
        },
        [driver, resequenceDriverTasks]
    );

    if (!firstSelectedStop) return null;

    const unselectedStopsWithStatusZero = unselectedStops.filter(
        ({ status }) => status === 0
    );

    return (
        <ManageStopControl
            className="live-stops-control"
            data-testid="live-stops-control"
        >
            {isViewMultiStopControl && (
                <LiveMultiStopsControl
                    selectedStops={selectedStops}
                    stopIdPropertyName={STOP_ID_PROPERTY_NAME}
                    footerButtonTitle={t('PlanStopsControl.button.deselectAll')}
                    footerButtonIcon="iconClose"
                    onClickFooterButton={deselectButtonClickHandler}
                    onSwitchView={setActiveView}
                    isTwoPartTask={isTwoPartTask}
                />
            )}
            {isViewSingleStopControl && (
                <LiveSingleStopControl
                    stopData={firstSelectedStop}
                    footerButtonTitle={t('PlanStopsControl.button.deselect')}
                    footerButtonIcon="iconClose"
                    onClickFooterButton={deselectButtonClickHandler}
                    onSwitchView={setActiveView}
                    disableResequencing={!isResequenceAllowed}
                />
            )}
            {isViewResequence && (
                <ResequenceModal
                    clickHandler={onCloseHandler}
                    onResequence={onResequence}
                    stopIdPropertyName={STOP_ID_PROPERTY_NAME}
                    isLiveStopControl
                    disableResequencing={!isResequenceAllowed}
                    selectedStopsData={selectedStops}
                    unselectedStopsData={unselectedStopsWithStatusZero}
                />
            )}
        </ManageStopControl>
    );
}

export default LiveStopsControl;
