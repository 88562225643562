/* global H */
import { isNumber } from 'lodash';
import Polyline from '~/utils/map/decodeUtils/polyline';
import {
    HereMapsGroup,
    HereMapsMapInstance,
    HereMapsObject,
    HereMapsPolyline,
    HereMapsPolygon,
    SpatialStyle,
    HereMapsShapes
} from '~/components/HereMaps/types';
import { Coordinates } from '~/api/types';
import { MutableRefObject } from 'react';
import { ArrowStyle } from '~/components/MapEngineProvider/types';
import {
    getPolygon,
    highlightPolygon,
    unhighlightPolygon
} from '~/utils/map/hereMaps/polygonUtils';
import { MakePolygonDrawingOptions } from '~/utils/map/map-drawing-utils';
import { hextoRGB } from '~/utils/map/drawing/utils';
import { HereMapsConstants } from '~/utils/hereMapsConstants';
import handleCircle from '~/ui/assets/icons/handleCircle.svg';

enum HereMapObjectEvents {
    DRAG = 'drag',
    POINTERENTER = 'pointerenter'
}

const MIN_POINTS = 2;

const { DEFAULT_POLYGON_FILL_OPACITY, DEFAULT_STROKE } =
    HereMapsConstants.polygons;

const decodePolylinePath = (encodedPath: string, precision = 5) => {
    const decodedPath = Polyline.decode(encodedPath, precision);
    return decodedPath.map((pair) => {
        const [lat, lng] = pair;
        return { lat, lng };
    });
};

const drawMapObject = (
    object: HereMapsObject,
    mapInstance: HereMapsMapInstance
) => {
    if (!object || !mapInstance) {
        return;
    }
    mapInstance.addObject(object);
};

const removeMapObject = (
    object: HereMapsObject,
    mapInstance: HereMapsMapInstance
) => {
    if (!object || !mapInstance) {
        return;
    }
    try {
        mapInstance.removeObject(object);
    } catch (e) {
        console.error(e);
    }
};

const getMapObjectFromAll = (
    allMapObjects: Record<string, HereMapsShapes>,
    id: string
) => {
    return allMapObjects[id];
};

const removeAllMapObjects = (
    allMapObjects: Record<string, HereMapsShapes>,
    mapInstance: HereMapsMapInstance
) => {
    for (const id in allMapObjects) {
        const mapObject = getMapObjectFromAll(allMapObjects, id);
        removeMapObject(mapObject, mapInstance);
    }
};

const getPolylineStyle = ({
    strokeColor,
    strokeWeight,
    lineDash,
    arrowStyle
}: {
    strokeColor?: string;
    strokeWeight?: number;
    lineDash?: number[];
    arrowStyle?: ArrowStyle;
}) => {
    const options = {
        ...(strokeColor && { strokeColor }),
        ...(isNumber(strokeWeight) && { lineWidth: strokeWeight }),
        ...(lineDash && { lineDash }),
        ...(arrowStyle && {
            arrowStyle: {
                ...arrowStyle,
                ...(strokeColor && { strokeColor })
            }
        })
    };
    return options;
};

const createPolyline = (
    path: Coordinates[],
    options: {
        strokeColor: string;
        lineWidth: number;
        lineDash?: number[];
        arrowStyle?: ArrowStyle;
    }
) => {
    const { strokeColor, lineWidth, lineDash = [0], arrowStyle } = options;
    if ((path?.length || 0) < MIN_POINTS) {
        return null;
    }
    const linestring = new H.geo.LineString();
    path.forEach((point) => {
        linestring.pushPoint(point);
    });
    const polyline = new H.map.Polyline(linestring, {
        style: {
            lineWidth,
            strokeColor,
            lineDash
        },
        data: {
            arrowLine: false
        }
    });
    const group = new H.map.Group();
    group.addObject(polyline);
    if (arrowStyle) {
        const arrowLine = new H.map.Polyline(linestring, {
            style: arrowStyle as SpatialStyle,
            data: {
                arrowLine: true
            }
        });
        group.addObject(arrowLine);
    }
    return group;
};

const updatePolyline = (
    path: Coordinates[],
    lineRef: MutableRefObject<HereMapsGroup | null>,
    style: {
        strokeColor: string;
        lineWidth: number;
        lineDash?: number[];
        arrowStyle?: ArrowStyle;
    }
) => {
    const { arrowStyle, ...lineStyle } = style;

    if ((path?.length || 0) < MIN_POINTS) {
        return lineRef.current ? lineRef : null;
    }
    if (lineRef.current) {
        const linestring = new H.geo.LineString();
        path.forEach((point: Coordinates) => {
            linestring.pushPoint(point);
        });
        const objects = lineRef.current.getObjects();
        objects.forEach((object) => {
            (object as HereMapsPolyline).setGeometry(linestring);
            const data = object.getData();
            const { arrowLine } = data || {};
            if (arrowLine && arrowStyle) {
                (object as HereMapsPolyline).setStyle(
                    arrowStyle as SpatialStyle
                );
                return;
            }
            (object as HereMapsPolyline).setStyle(lineStyle);
        });
    } else {
        const polylineGroup = createPolyline(path, style);
        lineRef.current = polylineGroup;
    }
    return lineRef;
};

const emphasizePolygon = (polygon: HereMapsPolygon) => {
    highlightPolygon(polygon);
};

const unEmphasizePolygon = (polygon: HereMapsPolygon) => {
    unhighlightPolygon(polygon);
};

/**
 * Get coordinates of a polygon
 *
 * @param polygon - the HereMaps `Polygon` instance
 */
const getPolygonCoordinates = (polygon: HereMapsPolygon) => {
    if (!polygon) {
        return null;
    }
    const coordinates: number[][] = [];
    polygon
        .getGeometry()
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        .getExterior()
        .eachLatLngAlt((lat: number, lng: number) => {
            coordinates.push([lng, lat]);
        });
    coordinates.push(coordinates[0]);
    return [coordinates];
};

const makePolygon = (
    polygonPaths: Coordinates[],
    makePolygonOptions: MakePolygonDrawingOptions
) => {
    const { color } = makePolygonOptions;
    const rgbaColor = hextoRGB(color as string, DEFAULT_POLYGON_FILL_OPACITY);
    const config = {
        perimeterCoordinates: polygonPaths,
        color: rgbaColor,
        strokeColor: color as string,
        strokeWeight: DEFAULT_STROKE
    };
    return getPolygon(config);
};

const makeResizablePolygon = (
    polygonPaths: Coordinates[],
    makePolygonOptions: MakePolygonDrawingOptions
) => {
    const polygon: HereMapsPolygon = makePolygon(
        polygonPaths,
        makePolygonOptions
    );
    (polygon as unknown as H.map.Marker).draggable = true;
    return polygon;
};

/**
 * Display a resizeable polygon on Here Maps map instance
 *
 * @param polygon - the HereMaps `Polygon` instance
 * @param mapInstance - the HereMaps `Map` instance
 * @returns cleanupResizablePolygon - callback to clea up resizeable polygon from map including event listeners
 */
export const displayResizablePolygon = (
    polygon: HereMapsPolygon,
    mapInstance: HereMapsMapInstance
) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const verticeGroup = new H.map.Group({ volatility: true, zIndex: 10 });

    const handlePointerEnter = () => {
        document.body.style.cursor = 'pointer';
    };

    verticeGroup.addEventListener(
        HereMapObjectEvents.POINTERENTER,
        handlePointerEnter,
        true
    );
    const mainGroup = new H.map.Group({
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        volatility: true,
        objects: [polygon, verticeGroup]
    });
    // create markers for each polygon's vertice which will be used for dragging
    polygon
        .getGeometry()
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        .getExterior()
        .eachLatLngAlt(
            (lat: number, lng: number, alt: string, index: number) => {
                const vertice = new H.map.Marker(
                    { lat, lng },
                    {
                        icon: new H.map.Icon(handleCircle, {
                            anchor: { x: 10, y: 10 }
                        })
                    }
                );
                vertice.draggable = true;
                vertice.setData({ verticeIndex: index });
                verticeGroup.addObject(vertice);
            }
        );

    const handleResizeEvent = (evt: H.mapevents.Event) => {
        const pointer = evt.currentPointer;

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const geoLineString = polygon.getGeometry().getExterior();

        const geoPoint = mapInstance.screenToGeo(
            pointer.viewportX,
            pointer.viewportY
        );

        // set new position for vertice marker
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        evt.target.setGeometry(geoPoint);

        // set new position for polygon's vertice
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        geoLineString.removePoint(evt.target.getData().verticeIndex);
        geoLineString.insertPoint(
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            evt.target.getData().verticeIndex,
            geoPoint
        );
        polygon.setGeometry(new H.geo.Polygon(geoLineString));
        // stop propagating the drag event, so the map doesn't move
        evt.stopPropagation();
    };

    const cleanupResizablePolygon = () => {
        verticeGroup.removeEventListener(
            HereMapObjectEvents.DRAG,
            handleResizeEvent as unknown as EventListenerOrEventListenerObject
        );
        verticeGroup.removeEventListener(
            HereMapObjectEvents.POINTERENTER,
            handlePointerEnter
        );
        mapInstance.removeObject(mainGroup);
    };

    verticeGroup.addEventListener(
        HereMapObjectEvents.DRAG,
        handleResizeEvent as unknown as EventListenerOrEventListenerObject,
        true
    );
    mapInstance.addObject(mainGroup);
    return cleanupResizablePolygon;
};

export const hereMapsDrawingUtils = {
    makePolygon,
    makeResizablePolygon,
    displayResizablePolygon,
    updatePolyline,
    createPolyline,
    drawMapObject,
    removeMapObject,
    removeAllMapObjects,
    getPolylineStyle,
    decodePolylinePath,
    getMapObjectFromAll,
    emphasizePolygon,
    unEmphasizePolygon,
    getPolygonCoordinates
};
