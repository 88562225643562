import React, { useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { selectTasks } from '~/reducers/tasksSlice';
import { selectIsOpenUnassignedTasksDrawer } from '~/reducers/mapDrawerSettingsSlice';
import {
    resetSelectedMapStops,
    selectSelectedMapStops
} from '~/reducers/selectedMapStopsSlice';
import { ManageStopControl } from '~/ui';
import { ResequenceModal } from '~/components/MapPage/ResequenceModal/ResequenceModal';
import PlanSingleStopControl from './PlanSingleStopControl';
import PlanMultiStopsControl from './PlanMultiStopsControl';
import { mapPlanStopUtils } from '~/utils/map';
import { usePlanMapPropsContext } from '~/components/MapPage/PlanMap/PlanMapPropsContext';
import ReassignModal from '~/components/MapPage/ReassignModal';
import constants from '~/utils/constants';
import { useStopResequence } from '~/hooks';
import { selectDispatchedDrivers } from '~/reducers/liveDriversSlice';
import { getNonDispatchedRoutes } from '~/components/MapPage/PlanMap/utils';

const STOP_ID_PROPERTY_NAME = 'clientRouteTaskId';
const { views } = constants.stopsControl;

export const PlanStopsControl = () => {
    const dispatch = useDispatch();
    const { planStops, routesLevelData } = usePlanMapPropsContext();
    const dispatchedDrivers = useSelector(selectDispatchedDrivers);
    const filteredRoutes = useMemo(() => {
        return getNonDispatchedRoutes({ dispatchedDrivers, routesLevelData });
    }, [dispatchedDrivers, routesLevelData]);

    const { resequenceStops } = useStopResequence();
    const tasksData = useSelector(selectTasks);
    const isOpenUnassignedTasksDrawer = useSelector(
        selectIsOpenUnassignedTasksDrawer
    );
    const selectedMapStopsIds = useSelector(selectSelectedMapStops);
    const [activeView, setActiveView] = useState(views.MAIN);

    const { t } = useTranslation('translation');

    const {
        firstSelectedPlanStopData,
        isDifferentRoutesSelected,
        isMultiStopsSelected,
        allPlanStops = [],
        selectedPlanStops = [],
        unselectedPlanStops = []
    } = useMemo(() => {
        if (!selectedMapStopsIds.length) {
            if (activeView !== views.MAIN) setActiveView(views.MAIN);
            return {};
        }

        const stopsData = isOpenUnassignedTasksDrawer
            ? mapPlanStopUtils.mapIdtoTasksData(selectedMapStopsIds, tasksData)
            : planStops;

        const { allStops, firstSelectedStop, selectedStops, unselectedStops } =
            mapPlanStopUtils.mapIdtoStopData(stopsData, selectedMapStopsIds);
        return {
            allPlanStops: allStops,
            firstSelectedPlanStopData: firstSelectedStop,
            isMultiStopsSelected: selectedStops.length > 1,
            selectedPlanStops: selectedStops,
            unselectedPlanStops: unselectedStops,
            isDifferentRoutesSelected:
                isOpenUnassignedTasksDrawer ||
                mapPlanStopUtils.stopsBelongToDifferentParentRoutes(
                    selectedStops,
                    routesLevelData
                )
        };
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [planStops, selectedMapStopsIds, activeView]);

    const deselectButtonClickHandler = useCallback(() => {
        dispatch(resetSelectedMapStops());
    }, [dispatch]);

    const onCloseHandler = useCallback(() => setActiveView(views.MAIN), []);

    const onResequence = useCallback(
        (selectedId, isInsertAfter = true) => {
            const selectedStop = allPlanStops.find(
                (stop) => stop[STOP_ID_PROPERTY_NAME] === selectedId
            );

            if (!selectedStop) {
                console.warn('Stop not selected');
                return;
            }

            const {
                stopNumber: targetStopNumber,
                clientRouteId: targetClientRouteId,
                routeId: targetRouteId
            } = selectedStop;
            resequenceStops({
                targetRouteId,
                targetClientRouteId,
                targetStopNumber,
                isInsertAfter
            });
        },
        [allPlanStops, resequenceStops]
    );

    const isViewMain = activeView === views.MAIN;
    const isViewResequence = activeView === views.RESEQUENCE;
    const isViewReassign = activeView === views.REASSIGN;
    const isViewMultiStopControl = isViewMain && isMultiStopsSelected;
    const isViewSingleStopControl = isViewMain && !isMultiStopsSelected;

    if (!firstSelectedPlanStopData) return null;

    return (
        <ManageStopControl
            className="plan-stops-control"
            data-testid="plan-stops-control"
        >
            {isViewMultiStopControl && (
                <PlanMultiStopsControl
                    selectedStops={selectedPlanStops}
                    stopIdPropertyName={STOP_ID_PROPERTY_NAME}
                    footerButtonTitle={t('PlanStopsControl.button.deselectAll')}
                    footerButtonIcon="iconClose"
                    onClickFooterButton={deselectButtonClickHandler}
                    onSwitchView={setActiveView}
                    disableResequencing={isDifferentRoutesSelected}
                />
            )}
            {isViewSingleStopControl && (
                <PlanSingleStopControl
                    stopData={firstSelectedPlanStopData}
                    footerButtonTitle={t('PlanStopsControl.button.deselect')}
                    footerButtonIcon="iconClose"
                    onClickFooterButton={deselectButtonClickHandler}
                    onSwitchView={setActiveView}
                    disableResequencing={isDifferentRoutesSelected}
                />
            )}
            {isViewResequence && (
                <ResequenceModal
                    clickHandler={onCloseHandler}
                    isDifferentRoutesSelected={isDifferentRoutesSelected}
                    selectedStopsData={selectedPlanStops}
                    unselectedStopsData={unselectedPlanStops}
                    stopIdPropertyName={STOP_ID_PROPERTY_NAME}
                    onResequence={onResequence}
                />
            )}
            {isViewReassign && (
                <ReassignModal
                    onClose={onCloseHandler}
                    routes={filteredRoutes}
                    selectedStops={selectedPlanStops}
                    stopIdPropertyName={STOP_ID_PROPERTY_NAME}
                />
            )}
        </ManageStopControl>
    );
};
