import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectSelectedDrawerCardDataCount } from '~/reducers/selectedDrawerCardDataSlice';
import { StopModalMenuButton } from '~/ui';
import { useCreateTripModal } from './useCreateTripModal';

export const CreateTripButton = () => {
    const { t } = useTranslation('translation');
    const { createTripModal, onShowModal } = useCreateTripModal();
    const numberOfSelectedDrawerCardData = useSelector(
        selectSelectedDrawerCardDataCount
    );

    if (numberOfSelectedDrawerCardData !== 1) return null;

    return (
        <>
            <StopModalMenuButton
                onClick={onShowModal}
                buttonIcon="route2"
                buttonText={t('PlanStopsControl.button.createTrip')}
                data-testid="button-create-trip"
            />
            {createTripModal}
        </>
    );
};
