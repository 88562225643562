import React from 'react';
import classnames from 'classnames';
import { Icon, useClickOutside } from '~/ui';
import { IconSize, IconSizes } from '~/ui/components/Icon/IconSizes';
import { DropDownMenuProps } from './types';

import './DropDownMenu.scss';

export const menuTitleTextDataTestIdSuffix = 'menutitle-text';

const DropDownMenu = React.forwardRef<HTMLDivElement, DropDownMenuProps>(
    function DropDownMenu(
        {
            title,
            placeholder,
            styles,
            children,
            isOpen,
            toggle,
            close,
            onMouseEnter,
            onMouseLeave,
            icon,
            position = 'down',
            customDropDownButton,
            className,
            'data-testid': dataTestId,
            helperText,
            isErrorText
        },
        ref
    ) {
        const dropdownStyles = styles || {
            innerContainer: 'dropdown-flex',
            menuTitle: 'dropdown-flex-item',
            menuTitleText: ''
        };

        const menuTitle = title || {};
        const dropDownItems = classnames({
            'dropdown-show-items': isOpen,
            'dropdown-hide-items': !isOpen,
            [position]: isOpen && position,
            [dropdownStyles.menuItems]: isOpen && dropdownStyles.menuItems
        });

        const elementClassName = classnames(
            'dropdown',
            '_p-relative',
            '_d-inline-block',
            className,
            dropdownStyles.outerContainer
        );

        const iconClassName = classnames(dropdownStyles.icon, {
            '_t-rotate-180': isOpen
        });

        useClickOutside(ref, close);

        const DropDownButtonComponent = customDropDownButton;

        return (
            <div
                className={elementClassName}
                ref={ref}
                data-testid={dataTestId || 'dropdown'}
            >
                <div
                    className={dropdownStyles.innerContainer}
                    onClick={toggle}
                    onKeyDown={toggle}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    role="button"
                    tabIndex={0}
                    data-testid={
                        dataTestId ? `${dataTestId}-button` : 'dropdown-button'
                    }
                >
                    {DropDownButtonComponent ? (
                        <DropDownButtonComponent />
                    ) : (
                        <div
                            className={dropdownStyles.menuTitle}
                            data-testid={
                                dataTestId
                                    ? `${dataTestId}-menutitle`
                                    : 'dropdown-menutitle'
                            }
                        >
                            {menuTitle.icon && (
                                <Icon
                                    className="dropdown-icon"
                                    icon={menuTitle.icon}
                                    color={menuTitle.iconColor}
                                    data-testid={
                                        dataTestId
                                            ? `${dataTestId}-menutitle-icon`
                                            : 'dropdown-menutitle-icon'
                                    }
                                />
                            )}
                            {placeholder && !menuTitle.name && (
                                <span
                                    className="dropdown--placeholder"
                                    data-testid="dropdown-placeholder"
                                >
                                    {placeholder}
                                </span>
                            )}
                            {menuTitle.name && (
                                <span
                                    className={classnames(
                                        dropdownStyles.menuTitleText,
                                        {
                                            'dropdown-title-text--error':
                                                isErrorText
                                        }
                                    )}
                                    data-testid={
                                        dataTestId
                                            ? `${dataTestId}-${menuTitleTextDataTestIdSuffix}`
                                            : 'dropdown-menutitle-text'
                                    }
                                >
                                    {menuTitle.name}
                                </span>
                            )}
                        </div>
                    )}
                    {icon && (
                        <Icon
                            className={iconClassName}
                            icon={icon}
                            color={dropdownStyles.dropdownToggleColor}
                            size={
                                (dropdownStyles.iconSize as IconSize) ||
                                IconSizes.M
                            }
                            data-testid={
                                dataTestId
                                    ? `${dataTestId}-icon`
                                    : 'dropdown-icon'
                            }
                        />
                    )}
                </div>
                <div
                    data-testid={
                        dataTestId ? `${dataTestId}-items` : 'dropdown-items'
                    }
                    className={dropDownItems}
                >
                    {children}
                </div>
                {helperText && (
                    <div
                        className={classnames(
                            'dropdown__helper',
                            { 'dropdown__helper--error': isErrorText },

                            '_d-flex',
                            '_ai-center',
                            '_pt-0'
                        )}
                        data-testid={
                            dataTestId
                                ? `${dataTestId}__helper-text`
                                : 'dropdown__helper-text'
                        }
                    >
                        {helperText}
                    </div>
                )}
            </div>
        );
    }
);

export default DropDownMenu;
