import React, { useMemo } from 'react';
import { DateTime, Duration } from 'luxon';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { RouteCardText } from '~/ui';
import { IconName } from '~/ui/components/Icon/IconNames';
import { IconSizes } from '~/ui/components/Icon/IconSizes';

import { useEtaTimeFormatter, useFormatTimeToClientTimeZone } from '~/hooks';

interface RouteCardEtaProps {
    stopData: {
        rawEta?: string;
        completedAt?: string;
        serviceWindow: {
            start: string;
            end: string;
        };
        isUnassigned?: boolean;
        isCompleted?: boolean;
        isInProgress?: boolean;
        isCanceled?: boolean;
        startServiceAt?: string;
        serviceTime?: string;
    };
    icon?: IconName;
    iconColor?: string;
    className?: string;
    'data-testid'?: string;
}

function RouteCardEta({
    stopData,
    icon,
    iconColor,
    className,
    ...extra
}: RouteCardEtaProps) {
    const { t } = useTranslation('common');
    const { formatTimeToClientTimeZone } = useFormatTimeToClientTimeZone();
    const { getFormattedEta } = useEtaTimeFormatter();

    const timeWindow = useMemo(() => {
        if (!stopData || stopData.isCanceled) {
            return '';
        }

        const {
            rawEta,
            completedAt,
            serviceWindow,
            isUnassigned,
            isCompleted,
            isInProgress,
            startServiceAt,
            serviceTime
        } = stopData;

        if (isCompleted && completedAt) {
            return t('stopsDrawer:completedTime', {
                timeWindow: formatTimeToClientTimeZone({ time: completedAt })
            });
        }

        if (isUnassigned && serviceWindow) {
            const parsedServiceWindow = {
                start: formatTimeToClientTimeZone({
                    time: serviceWindow?.start
                }),
                end: formatTimeToClientTimeZone({
                    time: serviceWindow?.end
                })
            };
            return t('time.timeWindow', parsedServiceWindow);
        }

        if (isInProgress) {
            const parsedInProgressServiceWindow = {
                start: formatTimeToClientTimeZone({
                    time: startServiceAt as string
                }),
                end: (
                    formatTimeToClientTimeZone({
                        time: startServiceAt as string,
                        shouldFormatTime: false
                    }) as DateTime
                )
                    .plus(Duration.fromISO(serviceTime as string))
                    .toFormat('t')
            };

            return t('time.estimatedTimeWindow', parsedInProgressServiceWindow);
        }

        return t('time.estimatedTime', {
            time: getFormattedEta(rawEta || '')
        });
    }, [stopData, formatTimeToClientTimeZone, t, getFormattedEta]);

    function getClassName() {
        const defaultClassName = 'routecard-eta';
        return classNames(defaultClassName, className);
    }

    return (
        <RouteCardText
            className={getClassName()}
            icon={icon}
            iconColor={iconColor}
            iconSize={IconSizes.S}
            data-testid={extra['data-testid'] || 'routecard-eta'}
        >
            {timeWindow}
        </RouteCardText>
    );
}

export default RouteCardEta;
